import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { BudgetObjectDialogService } from '../../shared/services/budget-object-dialog.service';
import { Configuration } from 'app/app.constants';
import { ObjectTypeDO, ObjectTypeService } from '../../shared/services/backend/object-type.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DialogContext } from '../../shared/types/dialog-context.interface';
import * as utils from 'app/shared/utils/notification-dialog.utils';
import { ConfirmType } from '../../shared/utils/notification-dialog.utils';
import { CompanyDataService } from '../../shared/services/company-data.service';

@Component({
  selector: 'tab-campaign-expense-group-type',
  templateUrl: './tab-campaign-expense-group-type.component.html',
  styleUrls: ['./tab-campaign-expense-group-type.component.scss']
})
export class GroupTypeTabComponent implements OnChanges, OnDestroy {
  @Input() companyId: number;
  private readonly destroy$ = new Subject<void>();
  groupTypeList: ObjectTypeDO[];
  appliedSorting = true;
  SearchPlaceHolder = 'Search Campaign and Expense Group Types';
  NoDataText = '';

  public gridColumns  = [
    { name: 'name', label: 'Campaign / Expense Group Type', sortable: true, width: '40%' },
    { name: 'is_enabled', label: 'Enabled', sortable: true, width: '25%' },
    { name: 'usage_count', label: 'Count', sortable: true, width: '20%' }
  ];

  enableSwitchTooltip = this.config.GroupTypeDescriptions.enableTooltip;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly service: ObjectTypeService,
    public readonly modal: Modal,
    private readonly dialogService: BudgetObjectDialogService,
    private readonly dialog: MatDialog,
    private config : Configuration,
    private companyDataService : CompanyDataService
  ) { 
    this.NoDataText = this.config.attributesAndTagsLabel.CAMPAIGN_TYPE;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.getGroupTypesList(this.companyId);
    }
  }

  getGroupTypesList(companyId: number) {
    this.utilityService.showLoading(true);
    this.service.getNewObjectTypes({ company: companyId })
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      data => this.onGroupTypesListLoaded(data),
      error => this.utilityService.handleError(error)
    );
  }
  
  updateStatusChange(data) {
    this.utilityService.showLoading(true);
    this.service.updateObjectType(data.id, data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onGroupTypeListEvent(this.config.GroupTypeDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  addGroupType(data) {
    this.utilityService.showLoading(true);
    data.is_custom = true;
    data.company = this.companyId;
    this.service.addObjectType(data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onGroupTypeListEvent(this.config.GroupTypeDescriptions.created),
      error => this.utilityService.handleError(error)
    );
  }

  updateGroupType(data) {
    this.utilityService.showLoading(true);
    this.service.updateObjectType(data.id, data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onGroupTypeListEvent(this.config.GroupTypeDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  deleteGroupType(row) {
    const deleteHandler = () => {
      this.utilityService.showLoading(true);
      this.service.deleteNewWorldObjectType(row.id, {company:this.companyId})
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          () => this.onGroupTypeListEvent(this.config.GroupTypeDescriptions.deleted),
          () => this.showDeleteNotificationDialog(row.name)
        );
    };
    const dialogMessage = `You will permanently lose the '${row.name}' campaign/expense group from everywhere it is used. '${row.name}' will be replaced with 'Other'. `;

    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: 'Permanently Delete Campaign/Expense Group?', message: dialogMessage });
  }


  showDeleteNotificationDialog(groupTypeName: string) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(ConfirmType.DeleteGroupType, groupTypeName);
    this.dialog.open(ConfirmationDialogComponent, {
      width: dialogData.width,
      data: dialogData
    });
  }

  onGroupTypeListEvent(msg : string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.utilityService.showLoading(false);
    this.getGroupTypesList(this.companyId);
  }

  onGroupTypesListLoaded(data) {
    this.groupTypeList = data;

    const updatedData = data.map(item => ({
      id: item.id,
      name: item.name,
      isCustom: item.is_custom,
      isEnabled: item.is_enabled,
      createdDate: item.crd && new Date(item.crd),
      updatedDate: item.upd && new Date(item.upd),
      companyId: this.companyId
    }));

    this.companyDataService._budgetObjectTypes$.next(updatedData)    
    this.companyDataService.campaignTypes.next(this.companyDataService.currentCampaignTypes = updatedData);
    this.companyDataService.programTypes.next(this.companyDataService.currentProgramTypes = updatedData);
    this.utilityService.showLoading(false);
  }
}
