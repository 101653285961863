import { Observable } from 'rxjs';
import { TableRowAmountsLoader } from '../../types/manage-ceg-table-row-data.types';
import { ObjectAmountsByTimeframes } from '@shared/types/object-amounts.interface';
import {
  BudgetTimeframeBrief,
  ManageCegTableCellAllocations,
  ManageCegTableRow,
} from '@manage-ceg/types/manage-ceg-page.types';
import { tap } from 'rxjs/operators';
import { calculatePresentationObjectSum, getRowAllocationsFromAmountsData } from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

interface AmountsLoaderBEService {
  getAmountsByTimeframes: (budgetId: number, objectIds: number[], filters?: object) => Observable<ObjectAmountsByTimeframes>
}

export abstract class BaseBudgetObjectAmountsLoader implements TableRowAmountsLoader {
  abstract readonly rowType: ManageTableRowType;

  public readonly amountsLoaderService: AmountsLoaderBEService;

  protected constructor(amountsLoaderService: AmountsLoaderBEService) {
    this.amountsLoaderService = amountsLoaderService;
  }

  fillRowAmounts(
    budgetId: number,
    rows: ManageCegTableRow[],
    params: object,
    timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>
  ): Observable<any> {
    this.manageParamsForGoals(params);
    return this.amountsLoaderService.getAmountsByTimeframes(budgetId, rows.map(row => row.objectId), params).pipe(
      tap(
        amountsData => rows.forEach(
          row => {
            if(amountsData[row.objectId]){
              row.allocations = getRowAllocationsFromAmountsData(amountsData[row.objectId]);
              const { amount_status, campaign_name, expense_group_name ,...rowAllocationFilteredValues } = row.allocations 
              row.presentationAllocations = calculatePresentationObjectSum<ManageCegTableCellAllocations>(rowAllocationFilteredValues, timeframesAll);
            }            
          }
        )
      )
    );
  }

  private manageParamsForGoals(paramsData: object): void {
    if (this.rowType === ManageTableRowType.Goal && paramsData['tag_ids']) {
      paramsData['children_tag_ids'] = paramsData['tag_ids'];
      delete paramsData['tag_ids'];
      delete paramsData['filtering_behaviour'];
    }
  }
}
