import { TableRowAmountsLoader, TableRowsDataLoader } from '../../types/manage-ceg-table-row-data.types';
import {
  BudgetTimeframeBrief,
  ManageCegTableRow,
} from '../../types/manage-ceg-page.types';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

export class ComposedTableRowsDataLoader implements TableRowsDataLoader {
  private rowDataLoaders: Record<string, TableRowAmountsLoader> = {};

  public static hasChildToDisplay(childRows: ManageCegTableRow[]): boolean {
    if (!childRows?.length) {
      return false;
    }
    for (const child of childRows) {
      if (!child.isFilteredOut) {
        return true;
      }
      if (child.children?.length) {
        return ComposedTableRowsDataLoader.hasChildToDisplay(child.children);
      }
    }
    return false;
  }

  private static getRowsSeparatedByLevel(rowsList: ManageCegTableRow[], chunkSize: number) {
    let currentChunkSize = 0;
    const rowsToInsertInTable = [];
    const childRowsToUpdate = [];

    const fillRowsToInsert = (rows: ManageCegTableRow[], rowsStore: ManageCegTableRow[]) => {
      for (const row of rows) {
        if (!row.isFilteredOut) {
          rowsStore.push(row);
          currentChunkSize++; // count only "visible" rows
        } else if (ComposedTableRowsDataLoader.hasChildToDisplay(row.children)) {
          rowsStore.push(row);
          fillRowsToInsert(row.children, childRowsToUpdate);
        }
        if (currentChunkSize === chunkSize) {
          break;
        }
      }
    };
    fillRowsToInsert(rowsList, rowsToInsertInTable);
    return [rowsToInsertInTable, childRowsToUpdate];
  }

  public setRowDataLoader(rowType: ManageTableRowType, loader: TableRowAmountsLoader): ComposedTableRowsDataLoader {
    this.rowDataLoaders[rowType] = loader;
    return this;
  }

  loadNextChunk(
    budgetId: number,
    rows: ManageCegTableRow[],
    timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>,
    chunkSize: number,
    filterParams: object
  ): Observable<ManageCegTableRow[]> {
    const rowsToProcess = rows.filter(row => !row.processed); // includes isFilteredOut rows!
    const [rowsToInsertInTable, childRowsToUpdate] = ComposedTableRowsDataLoader.getRowsSeparatedByLevel(rowsToProcess, chunkSize);
    if (!rowsToInsertInTable.length) {
      return of([]);
    }

    const rowsToProcessFlatArray: ManageCegTableRow[] = [...rowsToInsertInTable, ...childRowsToUpdate];
    const rowsToFillWithAmounts = rowsToProcessFlatArray.filter(row => !row.isFilteredOut); // update allocations only for visible rows
    let loadAmountsByTypeRequestArray;

    if (rowsToFillWithAmounts.length) {
      const chunkRowsToFillByType: Record<string, ManageCegTableRow[]> =
        rowsToFillWithAmounts.reduce(
          (res, row) => {
            res[row.type] = [...(res[row.type] || []), row];
            return res;
          },
          {}
        );

      loadAmountsByTypeRequestArray =
        Object.entries(chunkRowsToFillByType).map(
          ([rowType, rowsOfType]) => this.rowDataLoaders[rowType].fillRowAmounts(budgetId, rowsOfType, filterParams, timeframesAll)
        );
    }

    const updateAmounts$ = loadAmountsByTypeRequestArray?.length ? forkJoin(loadAmountsByTypeRequestArray) : of(true);

    return updateAmounts$.pipe(
      map(() => {
        rowsToProcessFlatArray.forEach(row => row.processed = true);
        return rowsToInsertInTable
      })
    );
  }
}
