import { Component, ViewChild, inject } from '@angular/core';
import { DetailsDrawerBaseComponent } from '../details-drawer-base';
import { ObjectDetailsCommonState, ProgramDetailsState } from '../../../types/budget-object-details-state.interface';
import { BudgetObjectActionsShared } from '../../../services/budget-object-actions-shared';
import { BudgetObjectTagsService } from '../../../services/budget-object-tags.service';
import { BudgetObjectAttachmentsService } from '../../../services/budget-object-attachments.service';
import { ObjectDetailsTabsDataService } from '../../../services/object-details-tab-data.service';
import { ProgramDetailsForm, ProgramDetailsService } from '../../../services/program-details.service';
import { getParentFromLocation } from '@shared/utils/location.utils';
import { BudgetObjectCreationContext } from '../../../types/details-creation-context.interface';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { messages, objectPlaceholderName } from '../../../messages';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { DrawerFormFields } from '../details-drawer-form';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { DataValidationService } from '../../../services/data-validation.service';
import { HistoryObjectLogTypeNames } from '@shared/types/history-object-log-type.type';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import {
  BudgetObject,
  BudgetObjectEvent,
  BudgetObjectEventContext,
  BudgetObjectEventType
} from '../../../types/budget-object-event.interface';
import { ProgramDO } from '@shared/types/program.interface';
import { ExpenseCostAdjustmentDataService } from '../../../../metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import {
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from '../../../services/budget-object-allocation.service';
import { BudgetObjectTasksService } from '../../../services/budget-object-tasks.service';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from '@shared/constants/storage.constants';
import { Budget } from '@shared/types/budget.interface';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { TaskListChangeEvent } from '../../tasks-list/tasks-list.component';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { DrawerTabItem, ObjectDetailsTabControl } from '../../../types/object-details-tab-control-type.interface';
import { BudgetAllocationActionsService } from '../../../../budget-allocation/services/budget-allocation-actions.service';
import { ManageTableBudgetColumn, ManageTableBudgetColumnName } from '@manage-ceg/types/manage-ceg-page.types';
import { budgetAllocationColumns } from '@manage-ceg/constants/manage-ceg-page.constants';
import { BudgetObjectCloneResponse } from '@shared/types/budget-object-clone-response.interface';
import { getParentObject } from '../../../utils/object-details.utils';
import { BudgetDataStateMutationService } from '../../../services/budget-data-state-mutation.service';
import { ProgramDetailsFormComponent } from 'app/budget-object-details/components/containers/program-details-form/program-details-form.component';
import { DrawerType } from '../../../services/drawer-stack.service';
import { CampaignDO } from '@shared/types/campaign.interface';
import { ProgramService } from '@shared/services/backend/program.service';
import { CustomFieldsService } from '../../custom-fields/custom-field.service';

@Component({
  selector: 'program-details-drawer',
  templateUrl: './program-details-drawer.component.html',
  styleUrls: ['./program-details-drawer.component.scss'],
  providers: [
    BudgetObjectActionsShared,
    BudgetObjectTagsService,
    BudgetObjectAllocationService,
    BudgetAllocationActionsService,
    BudgetObjectAttachmentsService,
    ObjectDetailsTabsDataService,
    ExpenseCostAdjustmentDataService
  ]
})
export class ProgramDetailsDrawerComponent extends DetailsDrawerBaseComponent<ProgramDetailsState> {
  protected objectDetailsService = inject(ProgramDetailsService);
  private readonly dataValidation = inject(DataValidationService);
  private readonly menuActionsBuilder = inject(BudgetObjectActionsBuilder);
  private readonly expenseCostAdjustmentDataService = inject(ExpenseCostAdjustmentDataService);
  private readonly budgetObjectAllocationService = inject(BudgetObjectAllocationService);
  protected readonly tasksManager = inject(BudgetObjectTasksService);
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);
  protected readonly gesturesManager = inject(BudgetAllocationActionsService);
  private readonly budgetDataStateMutator = inject(BudgetDataStateMutationService);
  private readonly programService = inject(ProgramService);
  private readonly customFieldsService = inject(CustomFieldsService);

  protected programTypes: BudgetObjectType[] = [];
  protected isCustomTypeEntering = false;
  protected formConfig = {
    [DrawerFormFields.name]: ['', {
      validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
      updateOn: 'blur'
    }],
    [DrawerFormFields.segment]: [null, this.dataValidation.segmentValidator()],
    [DrawerFormFields.ownerId]: [null, Validators.required],
    [DrawerFormFields.location]: null,
    [DrawerFormFields.typeId]: null,
    [DrawerFormFields.customType]: '',
    [DrawerFormFields.glCode]: null,
    [DrawerFormFields.poNumber]: ['', {
      validators: Validators.maxLength(500),
      updateOn: 'blur'
    }],
    [DrawerFormFields.notes]: '',
    [DrawerFormFields.vendorId]: null,
    [DrawerFormFields.vendorName]: '',
    [DrawerFormFields.currencyCode]: null,
    [DrawerFormFields.amountStatus]: CEGStatus.PLANNED,
  };
  protected hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null
  };
  public externalIntegration = {
    isExternal: false,
    integrationName: ''
  };
  protected tabsData: DrawerTabItem[] = this.tabsDataService.createTabList([
    ObjectDetailsTabControl.Details,
    ObjectDetailsTabControl.Allocation,
  ]);
  protected tableAllocationsColumns: ManageTableBudgetColumn[] = [
    budgetAllocationColumns[ManageTableBudgetColumnName.Budget],
    budgetAllocationColumns[ManageTableBudgetColumnName.Actual],
  ];

  isCustomFieldsFormValid: boolean;
  customFieldsStateDiffPayload: any;
  isCustomFieldsEnabledForProgram: boolean;
  resetCustomFieldsFormGroups: boolean;


  @ViewChild('detailsForm') detailsForm: ProgramDetailsFormComponent;
  hasCustomFieldChanges: boolean;
  customFieldsFormGroup: FormGroup;

  constructor() {
    super();
    this.setObjectType(this.configuration.OBJECT_TYPES.program);
  }

  protected onInit(): void {

    this.budgetObjectDetailsManager.budgetObjectEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budgetObjectEvent => this.handleBudgetObjectEvent(budgetObjectEvent));

    this.customFieldsService.getCFStatus().subscribe(status => {
      this.isCustomFieldsEnabledForProgram = status?.isCFEnabledForProgram;
    }) 
  }

  protected initObjectCreation() {
    this.showLoader();
    this.creationContext = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');

    this.loadBudgetAndCompanyData$().pipe(
      mergeMap(([company, budget]) => (
        combineLatest([
          this.objectDetailsService.initDetails(this.creationContext, { company, budget }),
          this.loadDetailsContextData$(company)
        ])
      )),
      map(([objectDetailsState]) => {
        this.setCreatedBy(objectDetailsState as ObjectDetailsCommonState);
        this.detectAddedParentObject(objectDetailsState as ObjectDetailsCommonState);
        objectDetailsState.currencyCode = this.company.currency;
        this.objectDetailsService.preFillStateAllocations(
          objectDetailsState,
          this.budgetTimeframes,
          { suppressMode: false, CEGMode: true }
        );
        if (this.creationContext?.selectedExpenses) {
          this.objectDetailsService.fillAllocationsFromExpenses(
            objectDetailsState,
            this.budgetTimeframes,
            this.creationContext.selectedExpenses
          );
        }
        this.programTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.programTypes);
        this.validateContextObjectType(this.programTypes, objectDetailsState as ObjectDetailsCommonState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => this.onProgramLoaded(state, false),
      error: error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText),
        true)
    });
  }

  protected loadObjectDetails(programId: number) {
    this.showLoader();
    this.loadBudgetAndCompanyData$().pipe(
      switchMap(([company, budget]) =>
        combineLatest([
          this.objectDetailsService.loadDetails(company, budget, programId, { isCEGMode: true }),
          this.loadDetailsContextData$(company)
        ])
      ),
      tap(([state]) => this.checkStateConsistencyAndAccess(state as ObjectDetailsCommonState)),
      map(([objectDetailsState]) => {
        this.initHierarchy(objectDetailsState);
        this.defineParent(objectDetailsState);
        this.programTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.programTypes, objectDetailsState.typeId);
        this.loadAttachments(objectDetailsState as ObjectDetailsCommonState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => {
        this.onProgramLoaded(state);
        this.budgetObjectDetailsManager.logObjectView(
          state.objectId,
          this.companyId,
          this.budget.id,
          this.currentCompanyUser.user,
          HistoryObjectLogTypeNames.program,
          this.objectDetailsService);
      },
      error: error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText),
        true
      )
    });
  }

  protected checkTagsLeftover(): void {
    if (!this.detailsForm.tagsControl) {
      return;
    }
    this.tagsManager.checkInputLeftover(this.detailsForm.tagsControl, this.currentState.tagMappings);
  }

  private loadDetailsContextData$(companyId: number): Observable<any> {
    return combineLatest(
      [
        this.companyDataService.selectedCompanyDO$.pipe(tap(company => this.company = company)),
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.companyDataService.budgetObjectTypes$.pipe(
          take(1),
          tap(types => this.programTypes = [...types, this.budgetObjectDetailsManager.enterCustomTypeOption])
        ),
        this.budgetObjectDetailsManager.getBudgets(this.budget?.id).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(tap(tfs => this.budgetTimeframes = tfs)),
        this.getSegmentRelatedData$,
        this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
        this.budgetObjectDetailsManager.getLightPrograms().pipe(tap(programs => this.programs = programs)),
        this.budgetObjectDetailsManager.getCompanyCurrencies(companyId).pipe(tap(currencyList => this.currencyList = currencyList)),
        this.getVendors$,
        this.getGLCodes$,
        this.currentCompanyUser$
      ]
    );
  }

  get isObjectOpen(): boolean {
    if (!this.currentState) {
      return false;
    }

    const { mode } = this.currentState;
    return mode === ObjectMode.Open || mode === ObjectMode.Planned;
  }

  protected handleGLCodeChanged(): void {
    this.spreadValueToChildExpenses(
      'GL Code',
      confirm => this.currentState.spreadGLCodeToChildren = confirm
    );
  }

  protected handlePONumberChanged(): void {
    this.spreadValueToChildExpenses(
      'PO Number',
      confirm => this.currentState.spreadPONumberToChildren = confirm
    );
  }

  private spreadValueToChildExpenses(fieldName: string, callback: (val: boolean) => void): void {
    const hasChildren = this.currentState.expenses.length > 0;
    if (hasChildren) {
      this.objectDetailsService
        .confirmSpreadValueToChildren(fieldName)
        .pipe(takeUntil(this.destroy$))
        .subscribe(confirm => callback(confirm));
    }
  }

  protected defineParent(objectDetailsState: ProgramDetailsState): void {
    objectDetailsState.parentObject = this.hierarchyService.getParentFromHierarchy(this.hierarchy, this.objectType);
  }

  private onProgramLoaded(state: ProgramDetailsState, syncState = true) {
    this.currentState = state as ObjectDetailsCommonState;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state as ObjectDetailsCommonState);
    }
    this.resetFormData();
    this.applyExternalIntegrationRestrictions();
    this.updateReadOnlyModeState();
    this.defineCompanyCurrency();
    this.loadCurrencyExchangeRates(this.currentState.currencyCode);
    this.updateOwnerOptions(this.currentState.segment.segmentId, this.currentState.segment.sharedCostRuleId);
    this.defineAllowedSegments(this.currentState.ownerId);
    this.setFormData();
    this.updateLocationOptions();
    this.onAllocationsUpdated();
    this.updateDetailsTabData();
    this.hideLoader();
    this.inheritParentAmountStatus(this.currentState.campaignId);

    if (!this.currentState.objectId) {
      this.fdCurrencyControl.disable();
    }

    this.formData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(merge(this.destroy$, this.reset$))
      ).subscribe(() => this.syncUnsavedChangesFlag());
  }

  protected getContextForNewObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForNewObjectCreation(this.currentState as ObjectDetailsCommonState);
  }

  protected formDataToState(): Partial<ProgramDetailsState> {
    const formData = this.formData.getRawValue() as ProgramDetailsForm;
    const state: Partial<ProgramDetailsState> = {
      name: formData[DrawerFormFields.name],
      notes: formData[DrawerFormFields.notes],
      typeId: formData[DrawerFormFields.typeId],
      ownerId: formData[DrawerFormFields.ownerId],
      segment: this.budgetObjectDetailsManager.hierarchyItemToState(formData.segment),
      glCode: formData[DrawerFormFields.glCode],
      poNumber: formData[DrawerFormFields.poNumber],
      vendor: formData[DrawerFormFields.vendorId],
      vendorName: formData[DrawerFormFields.vendorName],
      amountStatus: formData[DrawerFormFields.amountStatus],
      currencyCode: formData[DrawerFormFields.currencyCode],
    };

    const { OBJECT_TYPES } = this.configuration;
    const parentObject = getParentFromLocation(formData[DrawerFormFields.location]);
    state.parentObject = parentObject;
    state.goalId = parentObject?.type === OBJECT_TYPES.goal ? parentObject.id : null;
    state.campaignId = parentObject?.type === OBJECT_TYPES.campaign ? parentObject.id : null;

    return state;
  }

  private setFormData(): void {
    const {
      name, segment, ownerId, typeId = null, vendor, vendorName = '', currencyCode,
      glCode, amountStatus = CEGStatus.PLANNED, poNumber, notes = ''
    } = this.currentState;
    const location = this.locationService.defineLocationValue(this.currentState.parentObject);
    const segmentedValue = this.budgetObjectDetailsManager.segmentedValueToSelectItem(segment, this.segmentSelectItems);
    const owner = ownerId || (this.currentCompanyUser?.user);
    const formData: ProgramDetailsForm = {
      name,
      segment: segmentedValue,
      location,
      ownerId: owner,
      typeId: typeId || BudgetObjectActionsShared.getDefaultTypeId(this.programTypes),
      customType: '',
      glCode,
      poNumber,
      notes,
      vendorId: vendor,
      vendorName,
      currencyCode,
      amountStatus,
    };
    this.formData.setValue(formData);
    this.setFormValidators(name);
    this.performAutofill();
  }

  private setFormValidators(nameValue: string) {
    this.setNameValidator(nameValue, this.dataValidation);
  }

  public saveChanges(onSavedCb: Function = null): void {
    const isNewObject = !this.currentState.objectId;

    const save$ = allocationsCheckResult => this.budgetObjectActionsShared.saveContextData$(
      this.companyId,
      this.objectType,
      this.fdTypeId,
      this.programTypes,
      this.currentState.tagMappings,
      this.formData
    ).pipe(
      switchMap(() => this.saveDetailsData$()),
      switchMap((program: ProgramDO) => this.budgetObjectActionsShared.saveMappings(
        program,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((program: ProgramDO) => BudgetObjectActionsShared.saveTasks$(
        this.tasksManager,
        program,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((program: ProgramDO) => this.budgetObjectActionsShared.updateTagMappings$(
        program,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((program: ProgramDO) =>
        this.creationContext?.selectedExpenses?.length
          ? this.objectDetailsService.updateSelectedExpensesParent(program.id, this.creationContext.selectedExpenses)
          : of(program)
      ),
      switchMap(_ =>
        this.budgetObjectAllocationService.updateObjectRelatedAllocations$(
          allocationsCheckResult,
          this.prevState,
          this.currentState,
          this.budgetTimeframes,
          this.budget.suppress_timeframe_allocations
        )
      )
    );

    this.budgetObjectAllocationService.checkAllocationAmountForObjectDetails$(
      this.prevState,
      this.currentState,
      this.programs,
      this.campaigns,
      this.budget.suppress_timeframe_allocations,
      this.objectType
    ).pipe(
      switchMap((res: AllocationCheckResultData) => {
        if (res.result !== AllocationCheckResult.NeedOwnAllocationUpdate) {
          this.showLoader();
          return save$(res).pipe(map(_ => true));
        }
        return of(false);
      }),
      filter(saved => saved)
    ).subscribe({
      next: () => {
        this.onSavedSuccessfully(isNewObject);
        onSavedCb?.();
      },
      error: error => this.onError(
        error,
        messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText)
      )
    });
  }

  private saveDetailsData$(): Observable<ProgramDO> {
    const isNewObject = !this.currentState.objectId;
    this.saveFormData();
    let CFDetailsCtx = { isCFEnabledForProgram: this.isCustomFieldsEnabledForProgram, customFieldsStateDiff: this.customFieldsStateDiffPayload };

    return this.objectDetailsService.saveDetails(
      this.prevState,
      this.currentState,
      { suppressMode: this.budget.suppress_timeframe_allocations },
      CFDetailsCtx
    ).pipe(
      tap((program: ProgramDO) => {
        this.currentState.spreadSegmentToChildren = false;
        this.currentState.spreadGLCodeToChildren = false;
        this.currentState.spreadPONumberToChildren = false;
        this.currentState.externalId = program.external_id;
        this.currentState.updated = program.upd;
        if (isNewObject) {
          this.currentState.created = program.crd;
          this.programs.push(this.budgetDataService.convertProgram(program));
          LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, program.id);
        }
      })
    );
  }

  private onSavedSuccessfully(isNewObject: boolean): void {
    if (!this.prevState) {
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentCompanyUser.user,
        HistoryObjectLogTypeNames.program,
        this.objectDetailsService
      );
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.budgetObjectDetailsManager.refreshRecentlyAddedObjects(
        this.budget.id,
        HistoryObjectLogTypeNames.program,
        this.segments
      );
      this.updateMenuActions();
    }
    this.checkParentChange();

    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    const message = this.budgetObjectDetailsManager.defineSuccessMessage(this.objectType, isNewObject, this.objectTypeAsText);
    this.onSuccess(message, false);
    this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.currentState.objectId, this.objectType);
    this.triggerBudgetObjectEvent(
      this.currentState.objectId,
      isNewObject ? BudgetObjectEventType.Created : BudgetObjectEventType.Updated,
      this.currentState,
      this.prevState
    );
    this.syncUnsavedChangesFlag(false);
    this.setFormValidators(this.currentState.name);
    this.fdCurrencyControl.enable();
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.program,
      this.currentState.name
    );
    if (isNewObject) {
      LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, this.currentState.objectId);
    }

    if(this.isCustomFieldsEnabledForProgram){
      this.resetCustomFieldsFormGroups = true;
      this.hasCustomFieldChanges = false;
    }
  }

  private performAutofill() {
    this.budgetObjectDetailsManager.autofillSegmentValue(this.fdSegmentControl, this.segmentSelectItems);
    this.budgetObjectDetailsManager.autofillTypeSelectValue(this.fdTypeIdControl, this.programTypes.filter(type => !!type.id));
  }

  protected updateLocationOptions(): void {
    const currentLocation = this.fdLocation;
    this.setLocationOptions(
      {
        goals: this.goals,
        campaigns: this.campaigns,
        programs: [],
        currentLocation,
        segments: this.segments,
        rules: this.sharedCostRules,
        isPowerUser: this.isPowerUser
      }, currentLocation, false, false
    );
  }

  syncCustomFieldsFormValidity(isCustomFieldsFormValid: boolean) {
    this.isCustomFieldsFormValid = isCustomFieldsFormValid;
  }
  syncCustomFieldsFormGroup(formGroup: FormGroup) {
    this.customFieldsFormGroup = formGroup
  }

  customFieldsStateDiff(diffMap: any) {
    this.customFieldsStateDiffPayload = diffMap; 
    this.resetCustomFieldsFormGroups = false;
    this.hasCustomFieldChanges = Object.keys(diffMap).length > 0;;
  }

  public validateCustomFormFields() {
    let formGroup = this.customFieldsFormGroup;
    if(!formGroup) return;

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } 
    });
  }

  syncCustomFieldsUsageChanges(flag: boolean = false) {
    this.hasCustomFieldChanges = flag;
  }

  protected onObjectModeUpdated(item: ProgramDO | CampaignDO): void {
    this.prevState.mode = this.currentState.mode = item.mode;
    this.updateReadOnlyModeState();
    this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.objectId, this.objectType);
    this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Updated, this.currentState, this.prevState);
  }

  protected initHierarchy(objectDetailsState: ProgramDetailsState): void {
    this.hierarchy = this.hierarchyService.buildObjectHierarchy(
      {
        id: objectDetailsState.objectId,
        name: objectDetailsState.name,
        goalId: objectDetailsState.goalId,
        campaignId: objectDetailsState.campaignId,
      },
      this.objectType,
      { goals: this.goals, campaigns: this.campaigns }
    );
  }

  private applyExternalIntegrationRestrictions(): void {
    const fieldsToDisable = [DrawerFormFields.name, DrawerFormFields.typeId, DrawerFormFields.startDate];
    const { filteredObjectTypes, integrationTypeSelected } = this.applyExternalIntegrationToForm(
      fieldsToDisable,
      this.programTypes,
      this.currentState.typeId
    );

    this.hasExternalIntegrationType = integrationTypeSelected;
    this.programTypes = filteredObjectTypes;

    if (this.hasExternalIntegrationType) {
      this.externalIntegration = {
        isExternal: integrationTypeSelected,
        integrationName: filteredObjectTypes.find(objectType => objectType.id === this.currentState.typeId)?.name
      };
    }
  }

  protected updateMenuActions(): void {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder
      .reset()
      .addShowParentAction(this.objectLabel)
      .addCloneAction(this.objectLabel, this.handleClone.bind(this), this.isReadOnlyMode);

    if (this.isObjectOpen) {
      this.menuActionsBuilder.addCloseAction(
        this.objectLabel,
        this.handleClose.bind(this, messages.CLOSE_EXPENSE_GROUP_TITLE),
        !this.hasEditPermissions
      );
    } else {
      this.menuActionsBuilder.addOpenAction(this.objectLabel, this.handleOpen.bind(this), !this.hasEditPermissions);
    }
    const integrationExpenseTypeIds = BudgetObjectService.getExternalIntegrationTypeIds(this.programTypes);
    const isBudgetActionDisabled = integrationExpenseTypeIds.includes(this.currentState.typeId) || this.isReadOnlyMode;

    this.menuActionsBuilder
      .addChangeBudgetAction(isBudgetActionDisabled)
      .addDeleteAction(this.objectLabel, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  private handleClone(): void {
    const checkAndClone$ =
      this.objectDetailsService.getProgram(this.currentState.objectId, this.programs).pipe(
        switchMap(program =>
          this.budgetObjectAllocationService.checkAllocationAmountAndCloneSegmentedObject(
            program,
            this.objectType,
            program.campaignId,
            this.createCloneObjectRequest$(),
            this.campaigns,
            this.programs,
            this.budget.suppress_timeframe_allocations
          )
        ),
        switchMap(
          (cloneRes: BudgetObjectCloneResponse) =>
            this.objectDetailsService.getProgramDO(cloneRes.id).pipe(
              map(clonedProgramDO => {
                this.programs.push(this.budgetDataService.convertProgram(clonedProgramDO));
                const programDetailsState =
                  this.objectDetailsService.createProgramDetailsState(clonedProgramDO, [], [], []);
                programDetailsState.parentObject =
                  getParentObject(this.configuration, programDetailsState.goalId, programDetailsState.campaignId);
                this.triggerBudgetObjectEvent(programDetailsState.objectId, BudgetObjectEventType.Created, programDetailsState);
                return cloneRes;
              })
            )
        )
      );

    this.onObjectClone(programId => this.appRoutingService.replaceActiveDrawer(DrawerType.Program, programId), checkAndClone$);
  }

  private handleDelete(): void {
    this.dialogManager.openDeleteEntityDialog(() => {
    //   this.budgetObjectDetailsManager.detachDirectChildObjects({
    //     expenses: this.currentState.expenses
    //   }, this.objectType.toLowerCase())
    //     .pipe(
    //       switchMap(() =>
    //         this.objectDetailsService.deleteObject(this.currentState.objectId)
    //       ),
    //       tap(() => this.expenseCostAdjustmentDataService.setIntegrationExpenses(this.programs, this.programTypes))
    //     ).subscribe({
    //     next: () => {
    //       this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.objectTypeAsText), true);
    //       this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.objectId, this.objectType);
    //       this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Deleted, this.currentState);
    //       this.expenseCostAdjustmentDataService.disableExpenseCostAdjustment([this.currentState.objectId], this.companyId).subscribe();
    //     },
    //     error: error =>
    //       this.onError(error, messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText))
    //   });

    // Commenting the above single object delete code and replacing it with multi_delete api call
    this.programService.deleteMultiPrograms([this.currentState.objectId]).subscribe({
      next: () => {
        this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.objectTypeAsText), true);
        this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.objectId, this.objectType);
        this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Deleted, this.currentState);
        this.expenseCostAdjustmentDataService.disableExpenseCostAdjustment([this.currentState.objectId], this.companyId).subscribe();
      },
      error: error =>
        this.onError(error, messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText))
    });
    }, this.objectTypeAsText);
  }

  protected handleBudgetToMoveSelected(budget: Budget) {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      true,
      () => this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Moved, this.currentState)
    );
  }

  private triggerBudgetObjectEvent(
    programId: number,
    eventType: BudgetObjectEventType,
    currentState: ProgramDetailsState,
    prevState?: ProgramDetailsState
  ): void {
    const context: BudgetObjectEventContext = {
      objectId: programId,
      segmentId: currentState.segment?.segmentId,
      sharedCostRuleId: currentState.segment?.sharedCostRuleId,
      parentObject: currentState.parentObject,
      objectTypeId: currentState.typeId,
      objectName: currentState.name,
      objectMode: currentState.mode,
      amountStatus: currentState.amountStatus,
      externalId: currentState.externalId,
      createdDate: this.getObjectCreatedDate(currentState),
      currencyCode: currentState.currencyCode
    };

    if (prevState) {
      context.prevSegmentId = prevState.segment?.segmentId;
      context.prevSharedCostRuleId = prevState.segment?.sharedCostRuleId;
      context.prevParentObject = prevState.parentObject;
    }

    const prevProgram = this.budgetDataStateMutator.updatePrograms(eventType, context);
    context.extras = { prevObject: prevProgram };

    this.budgetObjectDetailsManager.triggerBudgetObjectEvent({
      targetObject: BudgetObject.Program,
      eventType,
      context
    });
  }

  protected handleSegmentChanged(selectedItem: HierarchySelectItem): void {
    const { segmentId, sharedCostRuleId } = this.budgetObjectDetailsManager.hierarchyItemToState(selectedItem);
    this.updateOwnerOptions(segmentId, sharedCostRuleId);
  }

  protected handleTypeChange(): void {
    this.isCustomTypeEntering = this.fdTypeId === this.budgetObjectDetailsManager.enterCustomTypeOption.id;
    this.resetUnsavedCustomTypes();
  }

  protected handleCustomTypeChange(): void {
    this.budgetObjectActionsShared.handleCustomTypeChange(this.formData, this.programTypes, this.companyId, this.prevState?.typeId);
    this.isCustomTypeEntering = false;
  }

  private resetUnsavedCustomTypes(): void {
    this.programTypes = this.programTypes.filter(
      cType => !cType.isCustom || cType.id !== this.budgetObjectDetailsManager.unsavedCustomTypeId
    );
    this.formData.patchValue({ [DrawerFormFields.customType]: '' });
  }

  protected handleTasksUpdate($event: TaskListChangeEvent): void {
    BudgetObjectActionsShared.handleTasksUpdate(
      $event, this.currentState as ObjectDetailsCommonState, this.prevState as ObjectDetailsCommonState
    );
    this.updateDetailsTabData();
    this.syncUnsavedChangesFlag();
  }

  private handleBudgetObjectEvent(budgetObjectEvent: BudgetObjectEvent): void {
    if (budgetObjectEvent.targetObject === BudgetObject.Expense) {
      this.eventAffectedCurrentState(budgetObjectEvent)
        .pipe(
          takeUntil(this.destroy$),
          filter(affected => affected)
        )
        .subscribe(
          () => this.refreshChildObjectsDependentData(budgetObjectEvent)
        );
    }
  }

  private eventAffectedCurrentState(budgetObjectEvent: BudgetObjectEvent): Observable<boolean> {
    const parentObject = budgetObjectEvent?.context?.parentObject;
    return this.hasParentInHierarchy(
      parentObject,
      { type: this.configuration.OBJECT_TYPES.program, id: this.currentState.objectId }
    );
  }

  private refreshChildObjectsDependentData(_budgetObjectEvent: BudgetObjectEvent): void {
    this.objectDetailsService.getProgramExpenses$(this.companyId, this.budgetId, this.currentState.objectId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      expenses => this.currentState.expenses = expenses
    );

    this.refreshProgramAllocations();
  }

  protected onCurrencyExchangeRatesUpdated(): void {
    this.refreshProgramAllocations();
  }

  private refreshProgramAllocations() {
    this.refreshStateAllocations(
      this.objectDetailsService.loadProgramAllocationAmounts(
        this.budgetId, this.currentState.objectId, this.currentState.currencyCode, this.currentState.allocations
      )
    );
  }
}
