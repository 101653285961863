import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'grid-with-search',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridWithSearchComponent implements OnInit {
  @Input() gridData: any[];
  @Input() gridColumns: any[];
  @Input() showSearch: boolean;
  @Input() showAddButton: boolean;
  @Input() showHeader: boolean;
  @Input() isNameCaseSensitive: boolean = false;
  @Input() AddButtonText: string = "Create New";
  @Input() SearchPlaceHolder: string = "";
  @Input() searchControlWidth = `380px`;
  @Input() gridWithAction = true;
  @Input() enableSwitchTooltip: string;
  @Input() hasDescriptionColumn: boolean = false;
  @Input() lastColumnwidth = '15%';
  @Input() customActions = false;
  @Input() customActionLabel: string = '';
  @Input() editableMaxLength: number = 128;
  @Input() set NoDataText(text: string) {
    if(text) {
      this.noDataText = this.config.attributesAndTags.emptyListText(text, /^[aeiou]/i.test(text) ? 'an' : 'a');
      this.primaryNoSearchDataText = this.config.attributesAndTags.emptySearchPrimaryText(text);
      this.secondaryNoSearchDataText = this.config.attributesAndTags.emptySearchSecondaryText(text); 
    }
  };

  get NoDataText() {
    return this.noDataText;
  };

  @Output() addNewGridItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() editGridItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateStatusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteGridItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchControl') searchControl;
  
  noDataText = 'No data available';
  primaryNoSearchDataText = ""
  secondaryNoSearchDataText = "";

  public appliedSorting = { column: '', reverse: false };
  existingNames: Set<string> = new Set();
  existingDescriptions: Set<string> = new Set();
  isNewItem: boolean = false;
  disabledCreateButton: boolean = false;
  searchedValue: string = '';
  filteredData: any[] = [];
  forceValidation: boolean;

  constructor(
    private config: Configuration,
    private el: ElementRef) { }

  ngOnInit() {
    this.appliedSorting = { column: this.gridColumns[0]?.name, reverse: false };
    this.updateFilteredData();
    this.updateExistingNames();
    if (this.hasDescriptionColumn) {
      this.updateExistingDescription();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridData) {
      this.updateFilteredData();
      this.updateExistingNames();
      if (this.hasDescriptionColumn) {
        this.updateExistingDescription();
      }
      if (this.searchControl) {
        this.searchControl.searchFilterControl.setValue('');
      }
      this.disabledCreateButton = false;
    }
  }

  updateFilteredData(e = '') {
    this.resetForceValidation();
    if (this.gridData) {
      let sortedData = this.sortData(this.gridData);
      this.filteredData = sortedData.filter(item =>
        item.name.toLowerCase().includes(e.toLowerCase())
      );
    } else {
      this.filteredData = [];
    }
    if (this.disabledCreateButton) {
      this.disabledCreateButton = false;
    }
  }

  updateExistingNames() {
    if (this.gridData) {
      let mappingFn = this.isNameCaseSensitive ? item => item.name.trim() : item => item.name.trim().toLowerCase();
      this.existingNames = new Set(this.gridData.map(mappingFn));
    } else {
      this.existingNames = new Set();
    }
  }

  updateExistingDescription() {
    if (this.gridData) {
      this.existingDescriptions = new Set(this.gridData.map(item => item.description.trim().toLowerCase()));
    } else {
      this.existingDescriptions = new Set();
    }
  }

  filterGridData(event) {
    this.updateFilteredData(event);
    this.searchedValue = event;
  }

  createNew() {
    const newUser = {
      name: '',
      is_enabled: true,
      is_custom: true,
      id: null,
      usage_count: '-'
    };
    if (this.hasDescriptionColumn) {
      newUser['description'] = '';
    }
    this.isNewItem = true;
    this.filteredData = [newUser, ...this.filteredData];
    this.disabledCreateButton = true;
  }

  handleValidationError(eve, row = null) {
    if (this.hasDescriptionColumn) {
      this.disabledCreateButton = this.forceValidation ?
        true : row.id ? eve : (row.name.trim() === '' || row.description.trim() === '');
    } else {
      this.disabledCreateButton = eve;
    }
  }

  handleValueChange(eve, row, columnName) {
    this.setFocusOnDescriptionValue(row);
    const updatedRow = row;
    updatedRow[columnName] = eve;
    if (row.id) {
      this.editGridItem.emit(updatedRow);
    }
    else {
      this.addNewGridItem.emit(updatedRow);
    }
  }

  deleteUser(row) {
    this.resetForceValidation();
    if (row.id) {
      this.deleteGridItem.emit(row);
    }
    else {
      this.filteredData = this.filteredData.filter(item => item !== row);
      this.disabledCreateButton = false;
    }
  }

  sortLabels(label) {
    const { column, reverse } = this.appliedSorting;
    const updReverse = label === column ? !reverse : false;
    this.appliedSorting = { column: label, reverse: updReverse };
    this.updateFilteredData(this.searchedValue);
  }

  resetForceValidation() {
    this.forceValidation = false;
  }

  setFocusOnDescriptionValue(row) {
    setTimeout(() => {
      if (this.hasDescriptionColumn) {
        if (row['description'].trim() === "") {
          if (this.el.nativeElement.querySelector("#description")) {
            this.el.nativeElement.querySelector("#description").focus();
          } else {
            this.el.nativeElement.querySelector(`#description-${row.id}`).click();
          }
          this.forceValidation = true;
          this.handleValidationError(true, row)
        } else {
          this.forceValidation = false;
          this.handleValidationError(false, row)
        }
      }
    });
  }

  toggleEnabled(eve, row) {
    this.resetForceValidation();
    if (this.hasDescriptionColumn) {
      if (row['description'].trim() === "") {
        const element = this.el.nativeElement.querySelector(`#description-${row.id}`);
        if (element) {
          this.el.nativeElement.querySelector(`#description-${row.id}`).click();
        }
        setTimeout(() => {
          this.forceValidation = true;
          this.handleValidationError(true, row)
        });
      } else {
        this.forceValidation = false;
        this.handleValidationError(false, row)
      }
    }
    const updatedRow = { ...row, is_enabled: eve.checked };
    this.updateStatusChange.emit(updatedRow);
  }

  private sortData(data: any[]): any[] {
    const { column, reverse } = this.appliedSorting;

    if (!column) {
      return data;
    }

    return data.sort((a, b) => {
      const valueA = this.extractColumnValue(a, column);
      const valueB = this.extractColumnValue(b, column);

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return reverse ? valueB - valueA : valueA - valueB;
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        return reverse ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
      } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        return reverse ? (valueB ? 1 : -1) : (valueA ? 1 : -1);
      } else {
        // Handle other types as needed
        return 0;
      }
    });
  }

  private extractColumnValue(item: any, columnName: string): any {
    const keys = columnName.split('.');
    return keys.reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : null, item);
  }

}
