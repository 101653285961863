import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModalWithIconHeaderComponent, ModalContext } from '../components/modal-with-icon-header/modal-with-icon-header.component';

@Injectable({
  providedIn: 'root',
})
export class IconHeaderModalService {
  constructor(public dialog: MatDialog) { }

  openInitialUpdatePopup(data: ModalContext, contentTemplate?: TemplateRef<any>, redHeader: boolean = false): Observable<any> {
    const dialogRef = this.dialog.open(ModalWithIconHeaderComponent, {
      width: '570px',
      data: data,
      panelClass: 'custom-dialog-container'
    });

    if (contentTemplate) {
      dialogRef.componentInstance.customContent = true;
      dialogRef.componentInstance.contentTemplate = contentTemplate;
    }

    dialogRef.componentInstance.redHeader = redHeader;
    return dialogRef.afterClosed();
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
