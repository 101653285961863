import { Component, ViewChild, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { DataValidationService } from '../../../services/data-validation.service';
import { ObjectAccessManagerService } from '@shared/services/object-access-manager.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { Budget } from '@shared/types/budget.interface';
import { HistoryObjectLogTypeNames } from '@shared/types/history-object-log-type.type';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { DetailsDrawerBaseComponent } from 'app/budget-object-details/components/containers/details-drawer-base';
import { DrawerFormFields } from 'app/budget-object-details/components/containers/details-drawer-form';
import { ObjectHierarchy } from 'app/budget-object-details/components/object-hierarchy-nav/object-hierarchy-nav.type';
import { messages, objectPlaceholderName } from 'app/budget-object-details/messages';
import { BudgetObjectActionsBuilder } from 'app/budget-object-details/services/budget-object-actions-builder.service';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';
import { BudgetObjectTagsService } from 'app/budget-object-details/services/budget-object-tags.service';
import { GoalDetailsService } from 'app/budget-object-details/services/goal-details.service';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { GoalDetailsState, ObjectDetailsCommonState } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { debounceTime, combineLatest, forkJoin, merge, mergeMap, Observable, of, switchMap, take, takeUntil, tap, filter, map } from 'rxjs';
import { BudgetObjectCreationContext } from 'app/budget-object-details/types/details-creation-context.interface';
import { GoalDO, GoalTypeDO } from '@shared/types/goal.interface';
import { Tag } from 'app/shared/types/tag.interface';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from '@shared/constants/storage.constants';
import {
  BudgetObject,
  BudgetObjectEvent,
  BudgetObjectEventContext,
  BudgetObjectEventType
} from 'app/budget-object-details/types/budget-object-event.interface';
import { BudgetObjectDetailsComponent } from 'app/budget-object-details/types/budget-object-details-component.interface';
import { GoalsService } from '@shared/services/backend/goals.service';
import { GoalTypeService } from '@shared/services/backend/goal-type.service';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { ObjectDetailsTabsDataService } from 'app/budget-object-details/services/object-details-tab-data.service';
import { CampaignAllocationDO } from '@shared/types/campaign.interface';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { DrawerTabItem } from 'app/budget-object-details/types/object-details-tab-control-type.interface';
import { injectRemainingAmountsIntoAllocation } from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { BudgetObjectCloneResponse } from '@shared/types/budget-object-clone-response.interface';
import { BudgetDataStateMutationService } from '../../../services/budget-data-state-mutation.service';
import { GoalDetailsFormComponent } from 'app/budget-object-details/components/containers/goal-details-form/goal-details-form.component';
import { DrawerType } from '../../../services/drawer-stack.service';
import { MetricUpdateService } from 'app/budget-object-details/services/metric-update.service';
import { MetricUpdateAction } from 'app/budget-object-details/types/metric-update-action.enum';
import { Metric } from 'app/budget-object-details/components/details-metrics/details-metrics.type';
import { finalize } from 'rxjs/operators';

interface GoalDetailsForm {
  name: string;
  typeId: number;
  customType: string;
  notes: string;
}

@Component({
  selector: 'goal-details-drawer',
  templateUrl: './goal-details-drawer.component.html',
  styleUrls: ['./goal-details-drawer.component.scss'],
  providers: [
    BudgetObjectActionsShared,
    BudgetObjectMetricsService,
    BudgetObjectTagsService,
    BudgetObjectAttachmentsService,
    BudgetAllocationActionsService,
    ObjectDetailsTabsDataService,
  ]
})
export class GoalDetailsDrawerComponent extends DetailsDrawerBaseComponent<GoalDetailsState> {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);
  private readonly goalDetailsService = inject(GoalDetailsService);
  private readonly goalsService = inject(GoalsService);
  private readonly goalTypeService = inject(GoalTypeService);
  private readonly objectAccessManager = inject(ObjectAccessManagerService);
  protected readonly objectDetailsService = inject(GoalDetailsService);
  private readonly menuActionsBuilder = inject(BudgetObjectActionsBuilder);
  private readonly dataValidation = inject(DataValidationService);
  private readonly budgetDataStateMutator = inject(BudgetDataStateMutationService);
  protected readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  protected readonly metricUpdateService = inject(MetricUpdateService);

  protected goalTypes: BudgetObjectType[] = [];
  protected isCustomTypeEntering = false;
  private transparencyAccessChecked = false;
  protected goalAllocations: Partial<CampaignAllocationDO[]> = [];
  protected hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null
  };
  protected tabsData: DrawerTabItem[] = this.tabsDataService.createTabList();
  protected childHierarchy: HierarchySelectItem[] = [];
  protected formConfig = {
    [DrawerFormFields.name]: ['', {
      validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
      updateOn: 'blur'
      // TODO: make sure name input has counter update value on keyPress event
    }],
    [DrawerFormFields.typeId]: [null, [Validators.required, Validators.min(0)]],
    [DrawerFormFields.customType]: '',
    [DrawerFormFields.notes]: '',
  };

  @ViewChild('detailsForm') detailsForm: GoalDetailsFormComponent;

  protected metricListUpdateAction = {
    [MetricUpdateAction.DELETE]: this.handleDeleteMetric.bind(this),
    [MetricUpdateAction.UPDATE]: this.handleUpdateMetric.bind(this)
  };

  constructor() {
    super();
    this.setObjectType(this.configuration.OBJECT_TYPES.goal);
    this.handleDrawerSaveEvent.handleSaveGoalCalled$.subscribe(() => {
      this.submitChanges(this.handleSaveAction.bind(this));
    });
  }

  protected initObjectCreation(): void {
    this.showLoader();
    const contextData: BudgetObjectCreationContext = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');

    this.loadBudgetAndCompanyData$().pipe(
      switchMap(([company, budget]) =>
        combineLatest([
          this.goalDetailsService.initDetails(contextData, { company, budget }),
          this.loadDetailsContextData$(company)
        ])
      ),
      map(([objectDetailsState]) => {
        this.initHierarchy(objectDetailsState);
        this.setCreatedBy(objectDetailsState as ObjectDetailsCommonState);
        this.goalTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.goalTypes);
        this.validateContextObjectType(this.goalTypes, objectDetailsState as ObjectDetailsCommonState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => this.onGoalLoaded(state, false),
      error: error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true
      )
    }
    );
  }

  protected loadObjectDetails(goalId: number): void {
    this.showLoader();
    this.loadBudgetAndCompanyData$().pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.goalDetailsService.loadDetails(companyId, budgetId, goalId, { isCEGMode: this.cegStatusEnabled }),
          this.loadDetailsContextData$(companyId, goalId)
        ])
      ),
      map(([objectDetailsState]) => {
        this.budgetObjectDetailsManager.checkObjectBudgetStateConsistency({
          state: objectDetailsState,
          budgetId: this.budget?.id
        });
        this.initHierarchy(objectDetailsState);
        this.goalTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.goalTypes, objectDetailsState.typeId);
        this.loadAttachments(objectDetailsState as ObjectDetailsCommonState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
        next: state => {
          this.onGoalLoaded(state);
          this.budgetObjectDetailsManager.logObjectView(
            state.objectId,
            this.companyId,
            this.budget.id,
            this.currentCompanyUser.user,
            HistoryObjectLogTypeNames.goal,
            this.goalDetailsService);
        },
        error: error => this.onError(
          error,
          messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
          true)
      }
    );
  }

  protected loadDetailsContextData$(companyId: number, objectId: number | null = null): Observable<any> {
    this.companyDataService.loadGoalTypes(companyId, this.loadTypesErrorCb);

    return combineLatest(
      [
        this.companyDataService.selectedCompanyDO$.pipe(tap(company => this.company = company)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.budgetObjectDetailsManager.getGoalTypes().pipe(
          tap(types => this.goalTypes = [...types, this.budgetObjectDetailsManager.enterCustomTypeOption])
        ),
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getSegments().pipe(tap(segments => this.segments = segments)),
        this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
        this.budgetObjectDetailsManager.getLightPrograms().pipe(tap(programs => this.programs = programs)),
        this.budgetObjectDetailsManager.getCompanyCurrencies(companyId).pipe(tap(currencyList => this.currencyList = currencyList)),

        this.companyDataService.productsAndMetrics$.pipe(
          tap(([products, mTypes]) => {
            this.metricsManager.setProducts(products);
            this.metricsManager.setTypes(mTypes);
          })
        ),
        this.budgetObjectDetailsManager.getBudgets(this.budget?.id).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(
          tap(tfs => this.budgetTimeframes = tfs),
          switchMap(() => this.processAllocations$())
        ),
        this.currentCompanyUser$
      ]
    );
  }

  protected processAllocations$(): Observable<any> {
    const goalEmptyAllocations: CampaignAllocationDO[] = this.budgetObjectDetailsManager.initBudgetObjectAllocations(
      [],
      this.budgetTimeframes,
      true
    ) as CampaignAllocationDO[];

    const getAllocations$ = !this.objectId ?
      of(goalEmptyAllocations) :
      this.goalsService.getAmountsByTimeframes(this.budget.id, [this.objectId]).pipe(
        map(amounts => {
          injectRemainingAmountsIntoAllocation(amounts[this.objectId], goalEmptyAllocations as CampaignAllocationDO[]);
          return goalEmptyAllocations as Partial<CampaignAllocationDO[]>;
        }),
      );

    return getAllocations$.pipe(
      tap(allocations => this.goalAllocations = allocations)
    );
  }

  protected handleBudgetToMoveSelected(budget: Budget) {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      true,
      () => this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Moved, this.currentState)
    );
  }

  protected checkTagsLeftover(): void {
    if (!this.detailsForm.tagsControl) {
      return;
    }
    this.tagsManager.checkInputLeftover(this.detailsForm.tagsControl, this.currentState.tagMappings);
  }

  protected handleCustomTypeChange(): void {
    const { customType } = this.formData.value;
    if (customType) {
      const existingType = this.goalTypes.find(
        goalType => goalType.name.toLowerCase() === customType.toLowerCase()
      );
      if (existingType) {
        this.formData.patchValue({ typeId: existingType.id, customType: '' });
      } else {
        this.createCustomGoalType(customType);
      }
    }
    this.isCustomTypeEntering = false;
  }

  protected handleTypeChange(): void {
    const { typeId } = this.formData.value;
    this.isCustomTypeEntering = typeId === this.budgetObjectDetailsManager.enterCustomTypeOption.id;
  }

  protected getContextForNewObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForNewObjectCreation(this.currentState);
  }

  protected updateMenuActions(): void {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder
      .reset()
      .addShowChildAction(this.objectLabel)
      .addCloneAction(this.objectLabel, this.handleClone.bind(this), this.isReadOnlyMode)
      .addDeleteAction(this.objectLabel, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  protected handleClone(): void {
    const clone$ = this.goalDetailsService.cloneObject(this.currentState.objectId)
      .pipe(
        switchMap(
          (cloneRes: BudgetObjectCloneResponse) =>
            this.objectDetailsService.getGoalDO(cloneRes.id).pipe(
              map(clonedGoalDO => {
                const clonedGoalState =
                  this.objectDetailsService.createGoalDetailsState(clonedGoalDO, [], [], [], [], []);
                this.triggerBudgetObjectEvent(clonedGoalState.objectId, BudgetObjectEventType.Created, clonedGoalState);
                return cloneRes;
              })
            )
        ),
        tap(() => this.budgetObjectDetailsManager.reportDetailsChange(this as unknown as BudgetObjectDetailsComponent))
      );

    this.budgetObjectDetailsManager.cloneObject(clone$, this.objectType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => this.appRoutingService.replaceActiveDrawer(DrawerType.Goal, data?.id),
        error: (err) => this.onError(
          err,
          messages.UNABLE_TO_CLONE_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
        )
      });
  }

  protected formDataToState(): Partial<GoalDetailsState> {
    const formData = this.formData.value as GoalDetailsForm;
    return {
      name: formData.name,
      notes: formData.notes,
      typeId: formData.typeId
    };
  }

  public saveChanges(onSavedCb: Function = null, completed: boolean = false): void {
    this.showLoader();
    const isNewObject = !this.currentState.objectId;

    forkJoin([
      this.tagsManager.createTags(
        this.companyId,
        this.tagsManager.getLocalTags()
      )
    ]).pipe(
      tap(([addedTags]) => {
        this.applyCreatedTags(addedTags);
      }),
      mergeMap(() => {
        this.saveFormData();
        return this.goalDetailsService.saveDetails(this.prevState, this.currentState);
      }),
      switchMap((data: GoalDO) => this.budgetObjectActionsShared.saveMappings(data,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType)
      ),
      tap((data: GoalDO) => {
        if (isNewObject) {
          LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, data.id);
        }
      })
    ).subscribe({
      next: () => {
        this.onSavedSuccessfully(completed, isNewObject);
        onSavedCb?.();
      },
      error: error =>
        this.onError(
          error,
          messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
        )
    }
    );
  }

  protected onSuccess(message: string, close = false): void {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (close) {
      this.handleCancelAction();
    }
  }

  protected handleDelete(): void {
    this.budgetObjectActionsShared.handleDeleteGoal(
      this.currentState,
      this.objectType,
      this.isDeleteActionObject,
      (message: string, close: boolean) => {
        this.onSuccess(message, close);
        this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Deleted, this.currentState);
      },
      this.onError
    );
  }

  protected childHierarchyClickHandler(event: Partial<HierarchySelectItem>): void {
    this.appRoutingService.routeDrawerActionByObjectType[event.objectType](event.objectId);
  }

  protected initHierarchy(objectDetailsState): void {
    const targetObject = {
      id: objectDetailsState.objectId,
      name: objectDetailsState.name,
      campaigns: objectDetailsState.campaigns,
      programs: objectDetailsState.programs
    };
    this.hierarchy = this.hierarchyService.buildObjectHierarchy(
      targetObject,
      this.objectType,
      { campaigns: this.campaigns, programs: this.programs }
    );
    this.childHierarchy = this.hierarchyService.buildChildHierarchy(
      targetObject,
      this.objectType,
      { campaigns: this.campaigns, programs: this.programs }
    );
  }

  protected handleUpdateMetric(metricId: number, metric: Metric): void {
    this.currentState.metricMappings = this.updateMetric(this.currentState.metricMappings, metricId, metric);
    this.prevState.metricMappings = this.updateMetric(this.prevState.metricMappings, metricId, metric);
  }

  protected handleDeleteMetric(metricId: number): void {
    this.currentState.metricMappings = this.currentState.metricMappings.filter(metric => metric.id !== metricId);
    this.prevState.metricMappings = this.currentState.metricMappings.filter(metric => metric.id !== metricId);
  }

  protected onInit(): void {
    this.budgetObjectDetailsManager.budgetObjectEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budgetObjectEvent => this.handleBudgetObjectEvent(budgetObjectEvent));

    this.metricUpdateService.metricUpdated$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(data => this.handleMetricMappingUpdate(data))
      )
      .subscribe(data => {
        this.metricListUpdateAction[data.updateData.action](data.updateData.metricMappingId || data.metricMapping.id, data.metricMapping);
      });
  }

  private onGoalLoaded(state, syncState = true): void {
    this.checkAccessToGoal(state);
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    }
    this.resetFormData();
    this.updateMenuActions();
    this.updateReadOnlyModeState();
    this.defineCompanyCurrency();
    this.setFormData();
    this.calculateAllocationTotals(this.goalAllocations);
    this.updateTabsData();
    this.hideLoader();

    this.formData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(merge(this.destroy$, this.reset$))
      ).subscribe(() => this.syncUnsavedChangesFlag());
  }

  private createCustomGoalType(customType: string) {
    this.showLoader();
    let newCreatedTypeId: number;
    this.goalTypeService.createGoalType({
      name: customType,
      is_custom: true,
      company: this.companyId
    }).pipe(
      switchMap((createdType: GoalTypeDO) => {
        newCreatedTypeId = createdType.id;
        this.companyDataService.loadGoalTypes(this.companyId);
        return this.budgetObjectDetailsManager.getGoalTypes(true)
          .pipe(take(1));
      }),
      finalize(() => this.hideLoader())
    ).subscribe((goalTypes: BudgetObjectType[]) => {
      this.goalTypes = [...goalTypes, this.budgetObjectDetailsManager.enterCustomTypeOption];
      this.formData.patchValue({ typeId: newCreatedTypeId, customType: '' });
      this.isCustomTypeEntering = false;
    });
  }

  private checkAccessToGoal({ campaigns, programs }): void {
    const isRegularUser = this.userDataService.isRegularUser(this.currentCompanyUser);
    if (isRegularUser) {
      const childObjects = [...campaigns, ...programs];
      const checkAccess = (object) => (
        this.objectAccessManager.hasAccessBySegmentData(
          object,
          this.segments,
          this.sharedCostRules
        )
      );
      let hasAccess;
      if (this.company.goal_detail_transparency) {
        hasAccess = childObjects.length ? childObjects.some(obj => checkAccess(obj)) : true;
      } else {
        hasAccess = childObjects.length ? childObjects.every(obj => checkAccess(obj)) : true;
      }

      if (!this.transparencyAccessChecked && !hasAccess) {
        this.transparencyAccessChecked = true;
        this.router.navigate([this.configuration.ROUTING_CONSTANTS.PLAN_DETAIL]);
        this.showRestrictedAccessMessage();
        this.appRoutingService.closeDetailsPage();
        return;
      }
    }
  }

  private showRestrictedAccessMessage() {
    this.dialogManager.openConfirmationDialog({
      content: 'Your administrator has restricted access to goal details.',
      submitAction: {
        label: 'OK',
        handler: () => null
      }
    });
}

  private setFormData(): void {
    const { typeId, notes = '', name = '' } = this.currentState;
    const formData: GoalDetailsForm = {
      name,
      typeId,
      customType: '',
      notes
    };

    this.formData.patchValue(formData);
    this.setFormValidators(name);
    this.autofillGoalTypeValue();
  }

  private setFormValidators(nameValue: string): void {
    this.setNameValidator(nameValue, this.dataValidation);
  }

  private autofillGoalTypeValue(): void {
    this.budgetObjectDetailsManager.autofillTypeSelectValue(
      this.formData.get('typeId'),
      this.goalTypes.filter(type => !!type.id)
    );
  }

  private applyCreatedTags(addedTags: Tag[]): void {
    if (addedTags && addedTags.length) {
      this.companyDataService.loadTags(this.companyId);
      this.tagsManager.applyAddedTags(addedTags, this.currentState.tagMappings);
    }
  }

  private onSavedSuccessfully(completedState: boolean, isNewObject: boolean): void {
    if (!this.prevState) {
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentCompanyUser.user,
        HistoryObjectLogTypeNames.goal,
        this.goalDetailsService
      );
      this.updateMenuActions();
    }
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    const message = this.budgetObjectDetailsManager.defineSuccessMessage(this.objectType, isNewObject, '', completedState);
    this.onSuccess(message);
    this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.objectId, this.objectType);
    this.triggerBudgetObjectEvent(
      this.currentState.objectId,
      isNewObject ? BudgetObjectEventType.Created : BudgetObjectEventType.Updated,
      this.currentState
    );
    this.syncUnsavedChangesFlag(false);
    this.setFormValidators(this.currentState.name);
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.goal,
      this.currentState.name
    );
    if (isNewObject) {
      LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, this.currentState.objectId);
    }
  }

  private triggerBudgetObjectEvent(goalId: number, eventType: BudgetObjectEventType, currentState: GoalDetailsState): void {
    const context: BudgetObjectEventContext = {
      objectId: goalId,
      objectTypeId: currentState.typeId,
      objectName: currentState.name,
      createdDate: this.getObjectCreatedDate(currentState)
    };

    const prevGoal = this.budgetDataStateMutator.updateGoals(eventType, context);
    context.extras = { prevObject: prevGoal };

    this.budgetObjectDetailsManager.triggerBudgetObjectEvent({
      targetObject: BudgetObject.Goal,
      eventType,
      context
    });
  }

  private handleBudgetObjectEvent(budgetObjectEvent: BudgetObjectEvent): void {
    if (budgetObjectEvent.targetObject !== BudgetObject.Goal) {
      this.eventAffectedCurrentState(budgetObjectEvent)
        .pipe(
          takeUntil(this.destroy$),
          filter(affected => affected)
        )
        .subscribe(
          () => this.refreshChildObjectsDependentData(budgetObjectEvent)
        );
    }
  }

  private eventAffectedCurrentState(budgetObjectEvent: BudgetObjectEvent): Observable<boolean> {
    const parentObject = budgetObjectEvent?.context?.parentObject;
    return this.hasParentInHierarchy(
      parentObject,
      { type: this.configuration.OBJECT_TYPES.goal, id: this.currentState.objectId }
    );
  }

  private refreshChildObjectsDependentData(budgetObjectEvent: BudgetObjectEvent): void {
    if (budgetObjectEvent.targetObject === BudgetObject.Expense) {
      this.objectDetailsService.getGoalExpenses$(this.companyId, this.budgetId, this.currentState.objectId).pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        expenses => this.currentState.expenses = expenses
      );
    } else {
      const updateChildObjects$: Observable<any> =
        budgetObjectEvent.targetObject === BudgetObject.Campaign ?
          this.getUpdatedCampaignsAndPrograms$(
            () => this.objectDetailsService.getGoalCampaigns$(this.companyId, this.budgetId, this.currentState.objectId)
          ) :
          this.getUpdatedPrograms$(
            () => this.objectDetailsService.getGoalPrograms$(this.companyId, this.budgetId, this.currentState.objectId)
          );

      updateChildObjects$?.pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => {
          this.initHierarchy(this.currentState);
          this.updateMenuActions();
        }
      );
    }

    this.processAllocations$().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.calculateAllocationTotals(this.goalAllocations);
      this.updateTabsData();
    });
  }
}
