import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  CreateCegItemTemplateEvent,
  ManageCegTableActionDataSource,
  ManageCegTableAllocationUpdatePayload,
  ManageCegTableCellAllocations,
  ManageCegTableRow,
  ManageCegViewMode,
  ManageTableBudgetColumnName,
  ManageTableRowTypeLabel,
  NewManageCegTableRowTemplate,
  ManageCEGTableContextData, ObjectStatusPayload
} from '@manage-ceg/types/manage-ceg-page.types';
import { UndoCallback } from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import {
  AllocationAmountsMap,
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from '../../budget-object-details/services/budget-object-allocation.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { createDeepCopy, roundDecimal, sumAndRound } from '@shared/utils/common.utils';
import { UtilityService } from '@shared/services/utility.service';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import {
  ManageCegPageApiService,
  DeleteObjectsResult,
  UpdateObjectsStatusResult
} from '@manage-ceg/services/manage-ceg-page-api.service';
import { ManageCegDataValidationService } from '@manage-ceg/services/manage-ceg-data-validation.service';
import { Budget } from '@shared/types/budget.interface';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { ProgramAllocation } from '@shared/types/budget-object-allocation.interface';
import { Goal } from '@shared/types/goal.interface';
import { Campaign, CampaignAllocationDO, CampaignDO } from '@shared/types/campaign.interface';
import { Program, ProgramDO } from '@shared/types/program.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { AllocatableRowTypes } from '../../manage-table/components/manage-table/manage-table.constants';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { ManageTableHelpers } from '../../manage-table/services/manage-table-helpers';
import { CompanyDataService } from '@shared/services/company-data.service';
import { BulkActionPayloads } from '../../manage-table/types/bulk-action-payloads.type';
import { BudgetObjectSegmentData } from '@shared/types/budget-object-segment-data.interface';
import { SegmentDataInheritanceAction } from '@shared/types/segment-data-inheritance.interface';
import {
  ManageTableBulkDataItem,
  ManageTableBulkParentData,
  ManageTableBulkSegmentData
} from '../../manage-table/types/manage-table-bulk-data-map.types';
import { SegmentDataInheritanceService } from '@shared/services/segment-data-inheritance.service';
import { ManageTableParentContext } from '../../manage-table/types/manage-table-parent-context.interface';
import { Configuration } from 'app/app.constants';
import { LocationService } from '../../budget-object-details/services/location.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { ExpenseCostAdjustmentDataService } from '../../metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { MetricIntegrationsProviderService } from '../../metric-integrations/services/metric-integrations-provider.service';
import { ManageCEGTableRowFactory } from '@manage-ceg/services/manage-ceg-table-row-factory';
import { calculatePresentationObjectSum, createAmountCegItem } from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { getRowAllocationsFromAmountsData } from './manage-ceg-table-row-data/amounts-loader.helpers';
import { ObjectAmountsByTimeframes } from '../../shared/types/object-amounts.interface';
import { CampaignService } from '../../shared/services/backend/campaign.service';

interface UpdateAllocationContext {
  prevValue?: number;
  diffValue?: number;
  value: number;
  valueToSave?: number;
  timeframeId: number;
  record: ManageCegTableRow;
}

interface UpdateSegmentAllocationContext {
  amountDiff: number;
  amount: number;
  timeframeId: number;
  record: ManageCegTableRow;
}

@Injectable()
export class ManageCegTableDataMutationService {
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly budgetObjectAllocationService = inject(BudgetObjectAllocationService);
  private readonly utilityService = inject(UtilityService);
  private readonly apiService = inject(ManageCegPageApiService);
  private readonly dataValidationService = inject(ManageCegDataValidationService);
  private readonly modeService = inject(ManageCegPageModeService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly configuration = inject(Configuration);
  private readonly destroy$ = new Subject<void>();
  private readonly companyDataService = inject(CompanyDataService);
  private readonly segmentDataInheritanceService = inject(SegmentDataInheritanceService);
  private readonly locationService = inject(LocationService);
  private readonly integrationsProviderService = inject(MetricIntegrationsProviderService);
  private readonly expenseCostAdjustmentDataService = inject(ExpenseCostAdjustmentDataService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly CampaignService = inject(CampaignService);


  public readonly grandTotalUpdateTrigger$ = new Subject<Partial<ManageCEGTableContextData>>();
  public readonly allocationUpdateTrigger$ = new Subject<ManageCegTableRow[]>();

  private _newItemTemplate: NewManageCegTableRowTemplate;

  private allocationRestrictionConflict = false;
  private readonly ERROR_MESSAGE = {
    FAILED_TO_UPDATE_ALLOCATION: 'Failed to update allocation',
    FAILED_TO_UPDATE_SEGMENT_ALLOCATION: 'Failed to update segment allocation',
    FAILED_TO_UPDATE_ALLOCATIONS: 'Failed to update allocations',
    FAILED_TO_UPDATE_OBJECT_AMOUNT: 'Failed to update object amount',
    FAILED_TO_DUPLICATE_OBJECT: 'Failed to duplicate object',
  };
  private readonly OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  static prepareAllocationsUpdatePayload(
    dataSource: ManageCegTableActionDataSource,
    amount: number,
    payload: ManageCegTableAllocationUpdatePayload = {}
  ): ManageCegTableAllocationUpdatePayload {
    const { timeframe, record } = dataSource;

    if (!payload[record.id]) {
      payload[record.id] = { record, updates: [] };
    }

    payload[record.id].updates.push({ timeframeId: timeframe?.id, amount });

    return payload;
  }

  updateAllocations(payload: ManageCegTableAllocationUpdatePayload, undoCallbacks?: UndoCallback[]): Observable<boolean> {
    this.allocationRestrictionConflict = false;

    let requestsChain$: Observable<any> = of(null);
    const result$ = new BehaviorSubject<boolean>(null);
    const rollbacks: Function[] = [];

    const globalRollback = () => {
      setTimeout(() => {
        rollbacks.forEach(rollback => rollback?.());
      });
    };

    const getProcessedPayloadForRecord$ = (payloadRecord: {
      record: ManageCegTableRow,
      updates: { timeframeId: number; amount: number; }[];
    }) => {
      if (!payloadRecord.updates.length || this.allocationRestrictionConflict) {
        return of(null);
      }
      const addRequestChain = newChain$ =>
        requestsChain$ = requestsChain$.pipe(
          switchMap(() => newChain$)
        );
      const payloadProcess = this.getUpdateAllocationsPayloadProcess(payloadRecord, rollbacks, undoCallbacks, addRequestChain);
      return this.allocationRestrictionConflict ? of(null) : payloadProcess;
    };

    const processedPayloads$ = Object.values(payload).map(payloadRecord =>
      getProcessedPayloadForRecord$(payloadRecord)
    );

    if (!processedPayloads$.length) {
      result$.next(false);
      return result$.asObservable();
    }

    this.executeUpdateAllocationPayloadProcessing(processedPayloads$, () => requestsChain$, globalRollback, result$);
    return result$.asObservable();
  }

  updateSegmentAllocation(payload: ManageCegTableAllocationUpdatePayload): Observable<boolean> {
    const rollbacks: Function[] = [];
    const globalRollback = () => {
      setTimeout(() => {
        rollbacks.forEach(rollback => rollback?.());
      });
    };
    const result$ = new BehaviorSubject<boolean>(null);

    const processedPayloads$ = Object.values(payload)
      .map(payloadRecord => {
        const record = this.tableDataService.getRecordById(payloadRecord.record.id);
        const { updates } = payloadRecord;
        if (!updates.length || record?.type !== ManageTableRowType.Segment) {
          return of(null);
        }
        const updates$ = [];
        updates.forEach(({ timeframeId, amount }) => {
          const prevAmount = record.allocations[timeframeId]?.[ManageTableBudgetColumnName.Budget].ownAmount;
          const amountDiff = sumAndRound(amount, -prevAmount);
          const updateContext: UpdateSegmentAllocationContext = {
            record,
            amount,
            amountDiff,
            timeframeId
          };
          const rollbackContext: UpdateSegmentAllocationContext = {
            record,
            amount: prevAmount,
            amountDiff: -amountDiff,
            timeframeId
          };

          rollbacks.push(() => this.updateSegmentAllocationState(rollbackContext));
          updates$.push(
            this.apiService.updateSegmentAllocation({
              timeframeId,
              segmentAmount: amount,
              grandTotal: this.tableDataService.getBudgetGrandTotals(),
              objectId: record.objectId
            }).pipe(
              tap(() => this.updateSegmentAllocationState(updateContext))
            )
          );
        });

        return forkJoin(updates$);
      });

    if (!processedPayloads$.length) {
      result$.next(false);
      return result$.asObservable();
    }

    this.tableDataService.setLoading(true);
    forkJoin(processedPayloads$)
      .pipe(
        tap(() => result$.next(true)),
        finalize(() => this.tableDataService.setLoading(false))
      ).subscribe({
        error: err => {
          globalRollback();
          this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_SEGMENT_ALLOCATION);
          result$.next(false);
          this.tableDataService.setLoading(false);
        }
      });

    return result$.asObservable();
  }

  private getUpdateAllocationsPayloadProcess(
    payloadRecord: { record: ManageCegTableRow; updates: { timeframeId: number; amount: number }[] },
    rollbacks: Function[],
    undoCallbacks: UndoCallback[],
    addRequestChain: (requestChain$: Observable<any>) => void
  ): Observable<AllocationCheckResultData> {
    const { record, updates } = payloadRecord;
    const { parentRecord, grandParentRecord } = this.getParentRecords(record.parentId, false);

    const allocationUpdates$ = [];

    updates.forEach(({ timeframeId, amount }) => {
      const result = this.prepareAllocationUpdate({ record, timeframeId, value: amount });
      if (result) {
        allocationUpdates$.push(result.request$);
        rollbacks.push(result.rollback);
      }
    });

    const addAllocationsUpdateToRequestChain = (checkResultData: AllocationCheckResultData) => {
      const parentTimeframeId = updates[0].timeframeId;

      const requestsChain$ = forkJoin(allocationUpdates$).pipe(
        switchMap(() => this.apiService.checkObjectShareCostRule(record)),
        switchMap(() => this.buildParentsRestrictionUpdateChain(
          checkResultData,
          parentRecord,
          grandParentRecord,
          parentTimeframeId,
          undoCallbacks
        ))
      );

      addRequestChain(requestsChain$);
    };

    return this.dataValidationService.validateRecordAllocationLimits(record, parentRecord).pipe(
      tap((checkResultData: AllocationCheckResultData) => {
        if (!this.skipParentsRestrictionUpdate(checkResultData)) {
          addAllocationsUpdateToRequestChain(checkResultData);
        }
      })
    );
  }

  private executeUpdateAllocationPayloadProcessing(
    processedPayloads$: Observable<AllocationCheckResultData | null>[],
    requestsChainProvider$: () => Observable<any>,
    globalRollback: () => void,
    result$: BehaviorSubject<boolean>
  ): void {
    forkJoin(processedPayloads$)
      .pipe(
        tap(() => this.tableDataService.setLoading(true)),
        switchMap(() => {
          if (this.allocationRestrictionConflict) {
            globalRollback();
            result$.next(false);
            return of(null);
          }

          return requestsChainProvider$().pipe(
            tap(() => result$.next(true))
          );
        }),
        catchError(err => {
          this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_OBJECT_AMOUNT);
          return of(null);
        })
      )
      .subscribe({
        error: err => {
          this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_ALLOCATIONS);
          this.tableDataService.setLoading(false);
          result$.next(false);
        },
        complete: () => this.tableDataService.setLoading(false)
      });
  }

  private prepareAllocationUpdate(context: UpdateAllocationContext): { rollback: Function, request$: Observable<any> } {
    const processedContext = this.processAllocationUpdateContext(context);
    const onError = (err: Error) => {
      this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_ALLOCATION);
      rollback();
      return of(null);
    };
    const rollback = () => this.rollbackAllocation(processedContext);

    try {
      return {
        request$: this.updateAllocation(processedContext).pipe(catchError(err => onError(err))),
        rollback
      };
    } catch (err) {
      onError(err);
      return null;
    }
  }


  private updateAllocation(updateContext: UpdateAllocationContext): Observable<ProgramAllocation | CampaignAllocationDO> {
    const { timeframeId, valueToSave, record } = updateContext;

    this.updateAllocationState(updateContext);
    return this.apiService.updateObjectAllocation(record, {
      company_budget_alloc: timeframeId,
      source_amount: valueToSave
    }).pipe(
      tap(() => this.allocationUpdateTrigger$.next([updateContext.record]))
    );
  }

  public updateGrandTotal(): void {
    const dataInputs = this.tableDataService.tableDataInputs;
    this.grandTotalUpdateTrigger$.next(dataInputs);
  }

  private getParentRecords(parentId: number | string, deepCopy = true): {
    parentRecord: ManageCegTableRow | null;
    grandParentRecord: ManageCegTableRow | null;
  } {
    const parentRecord = (typeof parentId === 'string'
        ? this.tableDataService.getRecordById(parentId)
        : this.tableDataService.getRecordByIdAndType(ManageTableRowType.Campaign, parentId)
    ) || null;
    const grandParentRecord = parentRecord?.parentId ? this.tableDataService.getRecordById(parentRecord.parentId) : null;

    return {
      parentRecord: deepCopy ? createDeepCopy(parentRecord) : parentRecord,
      grandParentRecord: deepCopy ? createDeepCopy(grandParentRecord) : grandParentRecord
    };
  }

  private skipParentsRestrictionUpdate(checkResultData: AllocationCheckResultData): boolean {
    if (checkResultData.result === AllocationCheckResult.NeedOwnAllocationUpdate) {
      if (checkResultData.message) {
        this.budgetObjectAllocationService.openParentAmountShortageDialog(checkResultData.message);
      }
      this.allocationRestrictionConflict = true;
      return true;
    }

    return false;
  }

  private buildParentsRestrictionUpdateChain(
    checkResultData: AllocationCheckResultData,
    parentRecord: ManageCegTableRow,
    grandParentRecord: ManageCegTableRow,
    parentTimeframeId: number,
    undoCallbacks?: UndoCallback[]
  ): Observable<void> {
    let parentsUpdate$: Observable<void> = of(null);

    if (
      checkResultData.result === AllocationCheckResult.NeedParentAllocationUpdate ||
      checkResultData.result === AllocationCheckResult.NeedParentAndGrandParentAllocationUpdate
    ) {
      if (undoCallbacks) {
        undoCallbacks.push(
          () => this.updateParentWithAmountDiff(
            parentRecord,
            -checkResultData.allocationDiff,
            parentTimeframeId
          )
        );
      }
      parentsUpdate$ = parentsUpdate$.pipe(
        switchMap(() => this.updateParentWithAmountDiff(
          parentRecord,
          checkResultData.allocationDiff,
          parentTimeframeId
        ))
      );
    }

    if (checkResultData.result === AllocationCheckResult.NeedParentAndGrandParentAllocationUpdate) {
      if (undoCallbacks) {
        undoCallbacks.push(
          () => this.updateParentWithAmountDiff(
            grandParentRecord,
            -checkResultData.grandParentAllocationDiff,
            parentTimeframeId
          )
        );
      }
      parentsUpdate$ = parentsUpdate$.pipe(
        switchMap(() => this.updateParentWithAmountDiff(
          grandParentRecord,
          checkResultData.grandParentAllocationDiff,
          parentTimeframeId
        ))
      );
    }

    return parentsUpdate$;
  }

  private updateParentWithAmountDiff(
    parentRecord: ManageCegTableRow,
    amountDiff: number,
    parentTimeframeId?: number,
    parentAllocationsDiff?: AllocationAmountsMap,
    rollback = false
  ): Observable<void> {
    const prepareAllocationUpdateRequest = (timeframeDiff: number, timeframeId?: number) => {
      const allocationAmount = parentRecord.allocations[timeframeId][ManageTableBudgetColumnName.Budget].ownAmount;
      let sharedAmountDiff = timeframeDiff;
      let fullAllocationValue;

      const rulesSegmentPercentage =
        this.tableDataService.getRulesSegmentPercentage(
          this.tableDataService.getSharedCostRule(parentRecord.sharedCostRuleId),
          parentRecord.segmentId
        );

      if (rulesSegmentPercentage != null) {
        sharedAmountDiff = roundDecimal(rulesSegmentPercentage * timeframeDiff, 2);
      }
      fullAllocationValue = sumAndRound(allocationAmount, -timeframeDiff);

      const sharedAllocationValue = sumAndRound(allocationAmount, -sharedAmountDiff);
      const parentAllocUpdate = this.prepareAllocationUpdate({
        record: parentRecord,
        timeframeId,
        value: sharedAllocationValue,
        valueToSave: fullAllocationValue,
      });

      return parentAllocUpdate.request$;
    };

    let allocationUpdates: Observable<any>[] = [];
    if (parentAllocationsDiff) {
      allocationUpdates = Object.entries(parentAllocationsDiff).map(
        ([ timeframeId, timeframeDiff ]) => prepareAllocationUpdateRequest(
          rollback ? -timeframeDiff : timeframeDiff,
          Number(timeframeId)
        )
      );
    } else {
      allocationUpdates.push(prepareAllocationUpdateRequest(amountDiff, parentTimeframeId));
    }

    const allocationUpdates$ = allocationUpdates.length ? forkJoin(allocationUpdates) : of(null);
    return allocationUpdates$.pipe(
      switchMap(() => this.apiService.checkObjectShareCostRule(parentRecord))
    );
  }

  private processAllocationUpdateContext(context: UpdateAllocationContext): UpdateAllocationContext {
    const { timeframeId, record, value, valueToSave } = context;
    const prevValue = record.allocations[timeframeId]?.[ManageTableBudgetColumnName.Budget]?.ownAmount;
    const diffValue = roundDecimal(value - prevValue, 2);
    let convertedValueToSave = valueToSave != null ? valueToSave : value;

    if (this.companyDataService.selectedCompanyDOSnapshot.currency !== record.currencyCode) {
      convertedValueToSave = this.budgetObjectDetailsManager.getConvertedAmount(
        convertedValueToSave,
        record.currencyCode,
        timeframeId,
        true
      );
    }

    return {
      ...context,
      prevValue,
      diffValue,
      valueToSave: convertedValueToSave
    };
  }

  private rollbackAllocation(updateContext: UpdateAllocationContext): void {
    const newContext: UpdateAllocationContext = {
      ...updateContext,
      value: updateContext.prevValue,
      diffValue: -updateContext.diffValue,
    };

    this.updateAllocationState(newContext);
  }

  private updateAllocationState(context: UpdateAllocationContext): void {
    const { record, value, diffValue, timeframeId } = context;

    const recordTimeframe = record.allocations[timeframeId];
    this.updateTableCellAllocations(recordTimeframe, value, diffValue);

    this.tableDataService.triggerRefreshTable();
  }

  private updateTableCellAllocations(cellAllocation: ManageCegTableCellAllocations, value: number, diffValue: number): void {
    cellAllocation[ManageTableBudgetColumnName.Budget].ownAmount = value;
    cellAllocation[ManageTableBudgetColumnName.Available].ownAmount += diffValue;
  }

  private updateSegmentAllocationState(context: UpdateSegmentAllocationContext): void {
    this.updateSegmentAllocationDependantValues(context, context.record);

    this.tableDataService.triggerRefreshTable();
    this.allocationUpdateTrigger$.next([context.record]);
  }

  private updateSegmentAllocationDependantValues(
    context: UpdateSegmentAllocationContext,
    targetRow: ManageCegTableRow
  ): void {
    const { amount, amountDiff, timeframeId } = context;
    targetRow.allocations[timeframeId][ManageTableBudgetColumnName.Budget].ownAmount = amount;
    targetRow.allocations[timeframeId][ManageTableBudgetColumnName.Budget].unallocated += amountDiff;
    targetRow.allocations[timeframeId][ManageTableBudgetColumnName.Available].ownAmount += amountDiff;
  }

  private showMessage(message: string): void {
    this.utilityService.showCustomToastr(message, null, { timeOut: 3000 });
  }

  private handleError(error: Error, message: string): void {
    console.warn(`[ManageCegTableDataService ERROR]: ${error.message}`);
    this.showMessage(message);
  }

  private getBulkActionPayloads(context: {
    targets: BulkActionTargets;
    processNested: boolean;
    segmentData?: BudgetObjectSegmentData;
    replaceSegmentWithParents?: boolean;
    parentData?: ManageTableParentContext;
  }): BulkActionPayloads<Partial<CampaignDO> | Partial<ProgramDO>> {
    const { targets, segmentData, parentData, replaceSegmentWithParents, processNested } = context;
    const getPayload = (id: number, objectType: string) => {
      const payload: any = {
        id,
        process_nested: processNested
      };

      if (segmentData) {
        payload.split_rule = segmentData.sharedCostRuleId;
        payload.company_budget_segment1 = segmentData.budgetSegmentId;
      }
      if (parentData) {
        const { segmentData: parentSegmentData, objectType: parentType, objectId: parentId } = parentData;

        if (replaceSegmentWithParents) {
          payload.split_rule = parentSegmentData.sharedCostRuleId;
          payload.company_budget_segment1 = parentSegmentData.budgetSegmentId;
        }
        if (objectType === this.OBJECT_TYPES.campaign) {
          payload.parent_campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
        }
        if (objectType === this.OBJECT_TYPES.program) {
          payload.campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
        }
        payload.goal = parentType === this.OBJECT_TYPES.goal ? parentId : null;
      }

      return payload;
    };

    return {
      campaigns: targets.campaigns.map(id => getPayload(id, this.OBJECT_TYPES.campaign)),
      expGroups: targets.expGroups.map(id => getPayload(id, this.OBJECT_TYPES.program))
    };
  }

  private checkObjectSegmentsMatching(
    objectIds: number[],
    targetSegmentData: BudgetObjectSegmentData,
    recordGetter: (objectId: number) => ManageCegTableRow
  ): boolean {
    return !objectIds.length || objectIds.every(objectId => {
      const record = recordGetter(objectId);

      return record &&
        record.segmentId === targetSegmentData.budgetSegmentId &&
        record.sharedCostRuleId === targetSegmentData.sharedCostRuleId;
    });
  }

  private addChildrenFromBulkActionTargets(parentRecord: ManageCegTableRow, targets: BulkActionTargets) {
    const { campaigns, expGroups } = targets;

    campaigns.forEach(campaignId => {
      const record = this.tableDataService.getRecordByIdAndType(ManageTableRowType.Campaign, campaignId);
      parentRecord.children.push(record);
    });
    expGroups.forEach(expGroupId => {
      const record = this.tableDataService.getRecordByIdAndType(ManageTableRowType.ExpenseGroup, expGroupId);
      parentRecord.children.push(record);
    });
  }

  private updateClosedFlag<T extends { id?: number }>(
    data: T[],
    recordType: ManageTableRowType,
    isClosed: boolean
  ) {
    data.forEach(item => {
      const record = this.tableDataService.getRecordByIdAndType(recordType, item.id);
      if (record) {
        record.isClosed = isClosed;
      }
    });
  }

  public duplicateObject(targetRow: ManageCegTableRow, onSuccess: Function): void {
    const message = `${ManageTableRowTypeLabel[targetRow.type]} duplicated`;
    let duplicateObjectChain$: Observable<AllocationCheckResultData> = of(null);
    let parentRecord: ManageCegTableRow = null;
    let grandParentRecord: ManageCegTableRow = null;

    if (AllocatableRowTypes.includes(targetRow.type) && targetRow.parentId) {
      const targetRowCopy = createDeepCopy(targetRow);
      const targetRowParents = this.getParentRecords(targetRow.parentId, false);

      parentRecord = targetRowParents.parentRecord;
      grandParentRecord = targetRowParents.grandParentRecord;
      if (parentRecord) {
        const parentRecordCopy = createDeepCopy(parentRecord);

        parentRecordCopy.children.push(targetRowCopy);
        duplicateObjectChain$ = this.dataValidationService.validateRecordAllocationLimits(targetRowCopy, parentRecordCopy);
      }
    }

    try {
      duplicateObjectChain$
        .pipe(
          switchMap((allocationCheckResultData: AllocationCheckResultData) => {
            if (allocationCheckResultData?.result === AllocationCheckResult.NeedOwnAllocationUpdate) {
              return of(null);
            }

            const parentAllocationDiff = allocationCheckResultData?.allocationDiff || 0;
            const grandParentAllocationDiff = allocationCheckResultData?.grandParentAllocationDiff || 0;
            const parentAllocationsDiff = allocationCheckResultData?.parentAllocationsDiff;
            const grandParentAllocationsDiff = allocationCheckResultData?.grandParentAllocationsDiff;

            this.tableDataService.setLoading(true);

            return this.apiService.duplicateAndFetchObject(targetRow)
              .pipe(
                tap(() => this.showMessage(message)),
                switchMap(clonedObject => {
                  if (!parentRecord || !clonedObject || !parentAllocationDiff) {
                    return of(clonedObject);
                  }

                  return this.updateParentWithAmountDiff(
                    parentRecord,
                    parentAllocationDiff,
                    null,
                    parentAllocationsDiff
                  ).pipe(
                    map(() => clonedObject)
                  );
                }),
                switchMap(clonedObject => {
                  if (!grandParentRecord || !clonedObject || !grandParentAllocationDiff) {
                    return of(clonedObject);
                  }

                  return this.updateParentWithAmountDiff(
                    grandParentRecord,
                    grandParentAllocationDiff,
                    null,
                    grandParentAllocationsDiff
                  ).pipe(
                    map(() => clonedObject)
                  );
                })
              );
          }),
        )
        .subscribe({
          next: res => onSuccess?.(res),
          error: err => this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_DUPLICATE_OBJECT)
        });
    } catch (err) {
      this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_DUPLICATE_OBJECT);
    }
  }

  public deleteObjects$(targets: BulkActionTargets): Observable<DeleteObjectsResult> {
    const deletedCampaigns: number[] = [];
    const companyId = this.companyDataService.selectedCompanySnapshot.id;

    return this.apiService.deleteObjects(targets)
      .pipe(
        tap(result => {
          const { campaigns, expGroups } = result;
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'deleted.', 'failed to delete.');

          if (campaigns?.success?.length) {
            deletedCampaigns.push(...campaigns.success);
          }
          if (expGroups?.success?.length) {
            this.expenseCostAdjustmentDataService.disableExpenseCostAdjustment(expGroups.success, companyId).subscribe();
          }
          this.showMessage(message);
        }),
        switchMap(result => {
          if (!deletedCampaigns.length) {
            return of(result);
          }

          const enabledMetricIntegrationsNames = this.companyDataService.enabledMetricIntegrationsNames;
          return !enabledMetricIntegrationsNames.length ? of(result) : forkJoin(
            enabledMetricIntegrationsNames.map(name => {
              return this.integrationsProviderService.metricIntegrationProviderByType(name)
                ?.deleteCampaignsMappings(companyId, deletedCampaigns)
                .pipe(
                  catchError(() => of(null))
                ) || of(null);
            })
          ).pipe(
            map(() => result)
          );
        })
      );
  }

  public updateObjectsType(
    targets: BulkActionTargets,
    objectTypeId: number,
    onSuccess: Function
  ) {
    const payloads: BulkActionPayloads<any> = {
      campaigns: targets.campaigns.map(id => ({ id, object_type: objectTypeId })),
      expGroups: targets.expGroups.map(id => ({ id, object_type: objectTypeId })),
    };

    this.apiService.updateObjects(payloads)
      .pipe(
        tap(result => {
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'updated.', 'failed to update.');

          this.showMessage(message);
        }),
      )
      .subscribe(
        res => onSuccess?.(res)
      );
  }

  public updateSegmentData(targets: BulkActionTargets, segmentData: BudgetObjectSegmentData, onSuccess: Function) {
    const targetsCount = targets.campaigns.length + targets.expGroups.length;
    let processNested = false;
    let childrenSegmentInheritance = false;

    const updateObjectFn = (shouldProcess: boolean) =>
      this.apiService.updateObjects(this.getBulkActionPayloads({
        targets,
        segmentData,
        processNested: shouldProcess,
      }));
    const confirmationModal$ = this.segmentDataInheritanceService
      .confirmSegmentChange(null, true, true, true, targetsCount > 1)
      .pipe(
        switchMap(action => {
          if (action === SegmentDataInheritanceAction.None) {
            return of(null);
          }
          childrenSegmentInheritance = true;
          processNested = action === SegmentDataInheritanceAction.Replace;
          this.tableDataService.setLoading(true);

          return updateObjectFn(processNested);
        })
      );

    (targets.campaigns.length ? confirmationModal$ : updateObjectFn(true)).pipe(
      tap(results => {
        if (results) {
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
          const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'moved successfully.', 'failed to move.');

          this.showMessage(message);
        }
      })
    ).subscribe(
      res => onSuccess?.(res, childrenSegmentInheritance)
    );
  }

  public undoSegmentDataUpdate(
    initialSegmentData: ManageTableBulkSegmentData,
    onSuccess: Function
  ) {
    const getPayload = (item: ManageTableBulkDataItem<BudgetObjectSegmentData>) => ({
      id: item.id,
      split_rule: item.data.sharedCostRuleId,
      company_budget_segment1: item.data.budgetSegmentId
    });
    const bulkPayload = {
      campaigns: initialSegmentData.campaigns.map(item => getPayload(item)),
      expGroups: initialSegmentData.expGroups.map(item => getPayload(item)),
    };

    this.tableDataService.setLoading(true);
    this.apiService.updateObjects(bulkPayload)
      .pipe(
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'moved successfully.', 'failed to move.');

            this.showMessage(message);
          }
        })
      )
      .subscribe(
        res => onSuccess?.(res)
      );
  }

  public upadteTargetAllocations = (budgetId : number, row : ManageCegTableRow) :Observable<ObjectAmountsByTimeframes> => {
    return this.CampaignService.getAmountsByTimeframes(budgetId, [row.objectId]);
  }

  public updateParentData(
    targets: BulkActionTargets,
    parentData: ManageTableParentContext,
    budgetTimeframes: BudgetTimeframe[],
    suppressTfAllocations: boolean,
    onSuccess: Function,
    movedItemsCount?: number,
    undoCallbacks?: UndoCallback[]
  ) {
    const { objectType: parentType, segmentData: parentSegmentData } = parentData;
    const { campaigns, expGroups } = targets;
    const targetsCount = campaigns.length + expGroups.length;
    const allExpGroupsSegmentsMatch = this.checkObjectSegmentsMatching(
      expGroups,
      parentSegmentData,
      id => this.tableDataService.getRecordByIdAndType(ManageTableRowType.ExpenseGroup, id)
    );
    const allCampaignsSegmentsMatch = this.checkObjectSegmentsMatching(
      campaigns,
      parentSegmentData,
      id => this.tableDataService.getRecordByIdAndType(ManageTableRowType.Campaign, id)
    );
    const isSegmentlessParent = !parentSegmentData.budgetSegmentId && !parentSegmentData.sharedCostRuleId;
    
    let changeLocationChoice$: Observable<SegmentDataInheritanceAction> = of(null);
    let replaceSegmentWithParents = false;
    let processNested = false;
    let parentRecord: ManageCegTableRow = null;
    let grandParentRecord: ManageCegTableRow = null;
    let childrenSegmentInheritance = false;

    if (!parentData.objectId) {
      return;
    }
    

    const updateParentsAndNotify = () => {
      return this.apiService.updateObjects(this.getBulkActionPayloads({
        targets,
        processNested,
        replaceSegmentWithParents,
        parentData
      })).pipe(
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(
              resultsCount,
              'moved successfully.',
              'failed to move.',
              movedItemsCount
            );

            this.showMessage(message);
          }
        })
      );
    };

    const updateParentChainData = (parentRecord, grandParentRecord) => {
      this.dataValidationService.validateRecordAllocationLimits(parentRecord, grandParentRecord)
    .pipe(
      switchMap((allocationCheckResultData: AllocationCheckResultData) => {
        if (!allocationCheckResultData || allocationCheckResultData?.result === AllocationCheckResult.NeedOwnAllocationUpdate) {
          return of(null);
        }
  
        const {
          allocationDiff: parentAllocationDiff,
          grandParentAllocationDiff,
          parentAllocationsDiff,
          grandParentAllocationsDiff
        } = allocationCheckResultData;
  
        return changeLocationChoice$
          .pipe(
            tap(changeSegmentAction => {
              processNested = changeSegmentAction === SegmentDataInheritanceAction.Replace;
            }),
            switchMap(action => {
              if (action === SegmentDataInheritanceAction.None) {
                return of(null);
              }
              this.tableDataService.setLoading(true);
  
              return updateParentsAndNotify();
            }),
            switchMap(results => {
              if (!parentRecord || !results || !parentAllocationDiff) {
                return of(results);
              }
              if (undoCallbacks) {
                undoCallbacks.push(
                  () => this.updateParentWithAmountDiff(
                    parentRecord,
                    -parentAllocationDiff,
                    null,
                    parentAllocationsDiff,
                    true
                  )
                );
              }
  
              return this.updateParentWithAmountDiff(
                parentRecord,
                parentAllocationDiff,
                null,
                parentAllocationsDiff
              );
            }),
            switchMap((results) => {
              if (!grandParentRecord || !results || !grandParentAllocationDiff) {
                return of(results);
              }
              if (undoCallbacks) {
                undoCallbacks.push(
                  () => this.updateParentWithAmountDiff(
                    grandParentRecord,
                    -grandParentAllocationDiff,
                    null,
                    grandParentAllocationsDiff,
                    true
                  )
                );
              }
  
              return this.updateParentWithAmountDiff(
                grandParentRecord,
                grandParentAllocationDiff,
                null,
                grandParentAllocationsDiff
              );
            })
          );
      })
    )
    .subscribe(
      res => onSuccess?.(res, childrenSegmentInheritance)
    );
    }

    // Moving to campaign, should check parent limits
    if (parentData.objectType === this.OBJECT_TYPES.campaign) {
      const campaignParents = this.getParentRecords(parentData.objectId);

      parentRecord = campaignParents.parentRecord;
      grandParentRecord = campaignParents.grandParentRecord;

      if (parentRecord) {
        this.addChildrenFromBulkActionTargets(parentRecord, targets);
      }
      if (!parentRecord.allocations) {
    
        this.upadteTargetAllocations(this.tableDataService.tableDataInputs.budget.id, parentRecord).subscribe(
          result => {
            parentRecord.allocations = getRowAllocationsFromAmountsData(result[parentRecord.objectId]);
            updateParentChainData(parentRecord, grandParentRecord);
          }
        );
      }
      else {
        updateParentChainData(parentRecord, grandParentRecord);
      }
    }

    
    // We need no parent allocation restrictions or segment inheritance checks when moving to a goal
    if (parentData.objectType === this.OBJECT_TYPES.goal) {
      updateParentsAndNotify().subscribe(
        res => onSuccess?.(res, childrenSegmentInheritance)
      );
      return;
    }

    if (!(allExpGroupsSegmentsMatch && allCampaignsSegmentsMatch) && parentType !== this.OBJECT_TYPES.goal && !isSegmentlessParent) {
      changeLocationChoice$ = this.locationService.requestSegmentConfirmation({
        title: 'Change Location',
        keepAllowed: true,
        multiUpdate: true,
        parentName: parentType.toLowerCase(),
        objectName: 'selected object'
      }).pipe(
        switchMap(changeLocationAction => {
          if (changeLocationAction === SegmentDataInheritanceAction.Replace) {
            replaceSegmentWithParents = true;
            return this.segmentDataInheritanceService.confirmSegmentChange(
              null,
              true,
              true,
              true,
              targetsCount > 1
            ).pipe(
              tap(changeSegmentAction => {
                if (changeSegmentAction !== SegmentDataInheritanceAction.None) {
                  childrenSegmentInheritance = true;
                }
              })
            );
          }

          return of(changeLocationAction);
        })
      );
    }

  }


  

  public undoParentDataUpdate(
    parentData: ManageTableBulkParentData,
    onSuccess: Function,
    movedItemsCount?: number
  ) {
    const getPayload = (
      item: ManageTableBulkDataItem<ManageTableParentContext>,
      objectType: string
    ) => {
      const { objectType: parentType, objectId: parentId } = item.data;
      const payload: any = {
        id: item.id,
        goal: parentType === this.OBJECT_TYPES.goal ? parentId : null
      };

      if (objectType === this.OBJECT_TYPES.campaign) {
        payload.parent_campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
      }
      if (objectType === this.OBJECT_TYPES.program) {
        payload.campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
      }

      return payload;
    };
    const bulkPayload = {
      campaigns: parentData.campaigns.map(item => getPayload(item, this.OBJECT_TYPES.campaign)),
      expGroups: parentData.expGroups.map(item => getPayload(item, this.OBJECT_TYPES.program)),
    };

    this.tableDataService.setLoading(true);
    this.apiService.updateObjects(bulkPayload)
      .pipe(
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(
              resultsCount,
              'moved successfully.',
              'failed to move.',
              movedItemsCount
            );

            this.showMessage(message);
          }
        })
      )
      .subscribe(
        res => onSuccess?.(res)
      );
  }

  public updateObjectsMode(targets: BulkActionTargets, mode: ObjectMode, onSuccess: Function) {
    const getPayload = (id: number) => ({ id, mode });
    const childObjectsData = {
      childExpenseGroups: targets.childExpGroups?.map(getPayload) ?? [],
      childCampaigns: targets.childCampaigns?.map(getPayload) ?? []
    };
    const payloads: BulkActionPayloads<any> = {
      campaigns: targets.campaigns.map(getPayload),
      expGroups: targets.expGroups.map(getPayload),
    };

    this.apiService.updateObjects(payloads)
      .pipe(
        tap(result => {
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = mode === ObjectMode.Closed
            ? ManageTableHelpers.getBulkOperationMessage(resultsCount, 'closed.', 'failed to close.')
            : ManageTableHelpers.getBulkOperationMessage(resultsCount, 'reopened.', 'failed to reopen.');

          this.showMessage(message);
        }),
      )
      .subscribe(
        res => {
          const { campaigns, expGroups } = res;

          this.updateClosedFlag<Partial<CampaignDO>>(campaigns.success, ManageTableRowType.Campaign, mode === ObjectMode.Closed);
          this.updateClosedFlag<Partial<ProgramDO>>(expGroups.success, ManageTableRowType.ExpenseGroup, mode === ObjectMode.Closed);
          this.updateClosedFlag<Partial<ProgramDO>>(childObjectsData.childCampaigns, ManageTableRowType.Campaign, mode === ObjectMode.Closed);
          this.updateClosedFlag<Partial<CampaignDO>>(childObjectsData.childExpenseGroups, ManageTableRowType.ExpenseGroup, mode === ObjectMode.Closed);

          onSuccess?.(res);
        }
      );
  }

  public changeObjectStatus(
    campaignIds: number[],
    programIds: number[],
    status: CEGStatus
  ): Observable<UpdateObjectsStatusResult> {
    const payloads: { [key: string]: ObjectStatusPayload } = {
      campaigns: { ids: campaignIds, amount_status: status },
      expGroups: { ids: programIds, amount_status: status }
    };

    return this.apiService.changeObjectAmountStatus(payloads, status)
      .pipe(
        tap(result => {
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'updated.', 'failed to update.');

          this.showMessage(message);
        }),
      );
  }

  public createNewItemTemplate(
    createItemTemplateEvent: CreateCegItemTemplateEvent
  ): void {
    const isSegmentView = this.modeService.viewMode === ManageCegViewMode.Segments;
    const recordContext = createItemTemplateEvent.contextRow;
    const recordIndex = createItemTemplateEvent.position;
    const parent = recordContext.parentId ? this.tableDataService.flatDataMap[recordContext.parentId] : null;
    const emptyAllocationValue = createAmountCegItem(null, v => ({ ownAmount: 0 }));
    const allocations = Object.keys(recordContext.allocations).reduce((values, allocationId) => {
      values[allocationId] = { ...emptyAllocationValue };
      return values;
    }, {});
    const presentationAllocations =
      calculatePresentationObjectSum<ManageCegTableCellAllocations>(allocations, this.tableDataService.timeframesAll);
    const inheritSegmentFromObject =
      parent && !isSegmentView && (parent.sharedCostRuleId || parent.segmentId) ?
        parent :
        recordContext;

    const rowTemplate: ManageCegTableRow = {
      id: null,
      objectId: null,
      itemId: null,
      type: recordContext.type,
      children: [],
      name: null,
      allocations,
      presentationAllocations,
      isEditable: false,
      isSelectable: false,
      segmentId: inheritSegmentFromObject.segmentId,
      sharedCostRuleId: isSegmentView ? null : inheritSegmentFromObject.sharedCostRuleId
    };

    const allowedParentType = [ManageTableRowType.Campaign, ManageTableRowType.Goal];
    this._newItemTemplate = {
      rowTemplate,
      parent: parent && allowedParentType.includes(parent.type) ? parent : null,
      recordsArray: this.getTargetContainer(isSegmentView, parent, recordContext),
      index: recordIndex,
    };
    this.newItemTemplate.recordsArray.splice(recordIndex, 0, rowTemplate);
  }

  public saveNewItemTemplate(
    name: string,
    userId: number,
    companyId: number,
    budget: Budget,
    timeframes: BudgetTimeframe[]
  ) {
    const resetNewItemTemplate = () => {
      this.newItemTemplate.recordsArray.splice(this.newItemTemplate.index, 1);
      this.newItemTemplate = null;
    };
    const getIdFromItemId = (subId: string): number => {
      const chunks = subId.split('_');
      return +chunks[1];
    };
    const createAllocations = (): ProgramAllocation[] => {
      return timeframes.map((tf: BudgetTimeframe) => ({
        source_amount: 0,
        company: companyId,
        company_budget_alloc: tf.id,
      }));
    };
    if (!name) {
      resetNewItemTemplate();
      return;
    }
    const rowTemplate = this.newItemTemplate.rowTemplate;
    rowTemplate.name = name;
    const parent = this.newItemTemplate.parent;
    const parentIsGoal = parent?.type === ManageTableRowType.Goal;
    const parentGoalId = parent && parentIsGoal ? parent.objectId : null;
    const parentCampaignId = parent && !parentIsGoal ? (parent.objectId || getIdFromItemId(parent.itemId.toString())) : null;
    const allocations = createAllocations();

    const payload: Record<string, string | number | ProgramAllocation[]> = {
      name: rowTemplate.name,
      budget: budget.id,
      company: companyId,
      split_rule: rowTemplate.sharedCostRuleId,
      company_budget_segment1: rowTemplate.segmentId,
      owner: userId,
      created_by: userId,
      goal: parentGoalId,
      object_type: null
    };

    if (rowTemplate.type === ManageTableRowType.Campaign) {
      payload.parent_campaign = parentCampaignId;
      payload.start_date = null;
      payload.end_date = null;
      payload.campaign_allocations = allocations;
    }
    if (rowTemplate.type === ManageTableRowType.ExpenseGroup) {
      payload.campaign = parentCampaignId;
      payload.program_allocations = allocations;
    }

    let completeMsg: string;
    this.tableDataService.setLoading(true);
    this.apiService.createObjectFromTemplate(rowTemplate.type, payload).pipe(
      catchError(err => {
        completeMsg = 'Some error has occurred';
        resetNewItemTemplate();
        return throwError(err);
      }),
      finalize(() => {
        this.tableDataService.setLoading(false);
        this.showMessage(completeMsg);
      }),
      takeUntil(this.destroy$)
    ).subscribe(newlyCreatedItem => {
      completeMsg = `${ManageTableRowTypeLabel[rowTemplate.type]} created`;
      const viewMode = this.modeService.viewMode;
      // TODO: need to investigate how sharedCostRulePercent add to the created object
      const sharedCostRulePercent = this.getSharedCostPercent(newlyCreatedItem);
      const record = {
        ...ManageCEGTableRowFactory.createObjectRow(
          rowTemplate.type,
          newlyCreatedItem,
          viewMode,
          null,
          ManageTableHelpers.isSegmentlessObject
        )
      };
      record.allocations = this.newItemTemplate.recordsArray[this.newItemTemplate.index].allocations;
      record.presentationAllocations = calculatePresentationObjectSum<ManageCegTableCellAllocations>(record.allocations, this.tableDataService.timeframesAll);
      this.newItemTemplate.recordsArray[this.newItemTemplate.index] = record;
      this.tableDataService.flatDataMap[record.id] = record;
      this.newItemTemplate = null;
      this.addNewObjectToSnapshot(rowTemplate.type, newlyCreatedItem);
      this.tableDataService.triggerRefreshTable();
    });
  }

  private addNewObjectToSnapshot(targetType: ManageTableRowType, createdObject: Campaign | Program): void {
    switch (targetType) {
      case ManageTableRowType.Campaign:
        this.budgetDataService.addCampaignToSnapshot(createdObject as Campaign);
        break;
      case ManageTableRowType.ExpenseGroup:
        this.budgetDataService.addProgramToSnapshot(createdObject as Program);
        break;
    }
  }

  private getSharedCostPercent(
    obj: Goal & { isPseudoObject?: boolean; splitRuleId?: number; budgetSegmentId?: number } | Campaign | Program
  ): number {
    const sharedCostRules = this.budgetDataService.sharedCostRulesSnapshot;

    return obj.isPseudoObject ?
      this.tableDataService.getRulesSegmentPercentage(
        sharedCostRules?.find(scr => scr.id === obj.splitRuleId),
        obj.budgetSegmentId
      ) :
      null;
  }

  private getTargetContainer(isSegmentView: boolean, parent: ManageCegTableRow, recordContext: ManageCegTableRow): ManageCegTableRow[] {
    let recordsArray: ManageCegTableRow[] = parent ? parent.loadedChildren : this.tableDataService.rows;
    if (isSegmentView && parent.type === ManageTableRowType.Campaign && parent.segmentId !== recordContext.segmentId) {
      // has parent in different segment
      const parentSegment = this.tableDataService.flatDataMap[ManageTableRowType.Segment.toLowerCase() + '_' + recordContext.segmentId];
      if (parentSegment) {
        recordsArray = parentSegment.children;
      } else {
        console.error('Wrong segment ID - ', recordContext.segmentId);
      }
    }
    return recordsArray;
  }

  public get newItemTemplate(): NewManageCegTableRowTemplate {
    return this._newItemTemplate;
  }

  public set newItemTemplate(template: NewManageCegTableRowTemplate) {
    this._newItemTemplate = template;
  }
}
