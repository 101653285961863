import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationExtras,
  NavigationStart,
  Params,
  Router
} from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { CompanyDataService } from './company-data.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import {
  CustomFilterModeType,
  FilterManagementService
} from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { Configuration } from 'app/app.constants';
import { Subject } from 'rxjs';
import { BudgetObjectCreationContext, DetailsCreationContext } from '../../budget-object-details/types/details-creation-context.interface';
import { UtilityService } from './utility.service';
import { ViewSectionName } from '../../dashboard/dashboard.types';
import { ActionInterceptorsService } from './action-interceptors.service';
import { AppRoutingInterceptors } from '../types/app-routing-interceptors.type';
import { PlanPageViewMode } from '../enums/plan-page-view-mode.enum';
import { ManageTableViewMode } from 'app/manage-table/types/manage-table-view-mode.type';
import { ManageTableDataMode } from 'app/manage-table/types/manage-table-data-mode.type';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { BackNavigationContext, LS_KEY_MANAGE_TABLE_STATE } from 'app/manage-table/services/manage-page.service';
import { LS_KEY_ACTIVE_METRIC_MASTER_ID } from 'app/metric-funnels/components/metrics-page/metrics-page.component';
import { MANAGE_PAGE_VIEW_MODE } from '../constants/modes.constant';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { ManageCegDataMode } from '@manage-ceg/types/manage-ceg-page.types';
import { DrawerStackService, DrawerType } from '../../budget-object-details/services/drawer-stack.service';
import { NavigationToastrData } from '@shared/types/navigation-toastr.type';
import { HttpParams } from '@angular/common/http';
import { CustomFieldFiltersManagementService } from 'app/header-navigation/components/filters/filter-services/custom-field-filter-management.service';

const FILTER_QUERY_PARAM_NAME = 'filter';
const FILTER_COMPANY_ID_PROP_NAME = 'companyId';
const FILTER_BUDGET_ID_PROP_NAME = 'budgetId';

interface FilterParamData {
  companyId?: number;
  budgetId?: number;
  filterSet?: FilterSet;
}

export type NavigationTrigger = 'imperative' | 'popstate' | 'hashchange';

export interface DetailsRouteData {
  routePath: string;
  idParam: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppRoutingService {
  public static readonly DETAILS_OBJECT_ID_PARAM_NAME = 'id';

  private readonly _triggerInitBackNavToast$ = new Subject<void>();
  public readonly triggerInitBackNavToast$ = this._triggerInitBackNavToast$.asObservable();

  public readonly DASHBOARD_SECTION_BY_OBJECT_TYPE: { [key: string]: ViewSectionName } = {
    [this.configuration.OBJECT_TYPES.goal]: ViewSectionName.goals,
    [this.configuration.OBJECT_TYPES.campaign]: ViewSectionName.campaigns,
    [this.configuration.OBJECT_TYPES.program]: ViewSectionName.buckets,
    [this.configuration.OBJECT_TYPES.segment]: ViewSectionName.segments,
    [ManageTableViewMode.CampaignsExpenses]: ViewSectionName.campaignsExpenses,
  };

  public routeActionByObjectType: Record<string, Function> = {
    [this.configuration.OBJECT_TYPES.goal]: (id: number) => this.openGoalDetails(id),
    [this.configuration.OBJECT_TYPES.campaign]: (id: number) => this.openCampaignDetails(id),
    [this.configuration.OBJECT_TYPES.program]: (id: number) => this.openProgramDetails(id),
    [this.configuration.OBJECT_TYPES.expenseGroup]: (id: number) => this.openProgramDetails(id),
  };

  public metricDetailsByObjectType: Record<string, Function> = {
    [this.configuration.OBJECT_TYPES.campaign]: (id: number, backUrl: string) => this.openCampaignMetricDetails(id, backUrl),
    [this.configuration.OBJECT_TYPES.goal]: (id: number, backUrl: string) => this.openGoalMetricDetails(id, backUrl),
  };

  public routeDrawerActionByObjectType: Record<string, Function> = {
    [this.configuration.OBJECT_TYPES.goal]: (id: number) => this.openChildDrawer(DrawerType.Goal, id),
    [this.configuration.OBJECT_TYPES.campaign]: (id: number) => this.openChildDrawer(DrawerType.Campaign, id),
    [this.configuration.OBJECT_TYPES.program]: (id: number) => this.openChildDrawer(DrawerType.Program, id),
    [this.configuration.OBJECT_TYPES.expenseGroup]: (id: number) => this.openChildDrawer(DrawerType.Program, id),
    [this.configuration.OBJECT_TYPES.metric]: (id: number) => this.openChildDrawer(DrawerType.Metric, id)
  };

  private _lastNavigationStartTrigger: NavigationTrigger = null;

  public static convertFilterSetToUrlQueryParam (companyId: number, budgetId: number, filterSet: FilterSet) {
    const resultFilledFilters = filterSet &&
      Object.keys(filterSet)
        .filter(key => filterSet[key] != null && filterSet[key].length > 0)
        .reduce((filledFilters, key) => ({...filledFilters, [key]: filterSet[key]}), {});
    return resultFilledFilters && JSON.stringify({
      ...resultFilledFilters,
      [FILTER_COMPANY_ID_PROP_NAME]: companyId,
      [FILTER_BUDGET_ID_PROP_NAME]: budgetId
    });
  }

  public static getHistoryStateProperty<T>(propName) {
    const historyState = typeof history !== 'undefined' ? history.state : null;
    return historyState && historyState[propName] as T;
  }

  constructor(
    private readonly utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly filterManagementService: FilterManagementService,
    private readonly customFieldFiltersManagementService: CustomFieldFiltersManagementService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly configuration: Configuration,
    private readonly actionInterceptorsService: ActionInterceptorsService,
    private readonly stackManager: DrawerStackService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(
        event => this._lastNavigationStartTrigger = (event as NavigationStart).navigationTrigger
      );
  }

  public get lastNavigationStartTrigger(): NavigationTrigger {
    return this._lastNavigationStartTrigger;
  }

  public get shouldRestoreState(): boolean {
    return this.router.getCurrentNavigation().extras.state?.shouldRestoreState;
  }

  public updateCurrentFiltersInRouting(companyId: number, budgetId: number, filterSet: FilterSet) {
    this.safeNavigateTo([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [FILTER_QUERY_PARAM_NAME]: AppRoutingService.convertFilterSetToUrlQueryParam(companyId, budgetId, filterSet)
      },
      queryParamsHandling: 'merge'
    });
  }

  public safeNavigateTo(commands: any[], extras?: NavigationExtras) {
    const activeNavigation = this.router.getCurrentNavigation(); // Detect current active navigation, if any
    if (activeNavigation) { // Wait for current active navigation to complete
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError),
        take(1)
      ).subscribe(() => this.router.navigate(commands, extras));
    } else {
      this.router.navigate(commands, extras);
    }
  }

  public setInitDataFromQueryParams(params: Params) {
    const filterParamData = this.parseFilterDataFromParams(params);
    if (!filterParamData) {
      return;
    }

    if (filterParamData.companyId) {
      this.companyDataService.initWithCompany(filterParamData.companyId);

      if (filterParamData.budgetId) {
        this.budgetDataService.initBudgetId = filterParamData.budgetId;

        if (filterParamData.filterSet) {
          this.filterManagementService.initWithFilterSet(filterParamData.filterSet);
        }
      }
    }
  }

  public setInitFiltersForHistoryNavigation() {
    if (this.lastNavigationStartTrigger !== 'popstate') {
      return;
    }

    const filterParamData = this.parseFilterDataFromParams(this.router.routerState.root.snapshot.queryParams);
    if (!filterParamData) {
      return;
    }

    this.filterManagementService.initWithFilterSet(filterParamData.filterSet || {});
  }

  private parseFilterDataFromParams(params: Params): FilterParamData {
    let filterParamData: FilterParamData = {};

    if (!params || !params[FILTER_QUERY_PARAM_NAME]) {
      return null;
    }

    try {
      const parsedFilters = JSON.parse(params[FILTER_QUERY_PARAM_NAME]);
      if (!parsedFilters) {
        return null;
      }
      filterParamData = this.createFilterParamData(parsedFilters);
    } catch (e) {
      console.log('Failed to parse filters from query params');
      return null;
    }

    return Object.keys(filterParamData).filter(data => !!data).length > 0 ? filterParamData : null;
  }

  private createFilterParamData(parsedFilters: any) {
    const filterParamData: FilterParamData = {};
    filterParamData.companyId = Number(parsedFilters[FILTER_COMPANY_ID_PROP_NAME]);
    filterParamData.budgetId = Number(parsedFilters[FILTER_BUDGET_ID_PROP_NAME]);

    const filterSetItems =
      Object.keys(parsedFilters).filter(
        key => this.filterManagementService.isKnownFilterKey(key)
      );

    if (filterSetItems.length > 0) {
      filterParamData.filterSet =
        filterSetItems.reduce(
          (filters, key) => ({...filters, [key]: parsedFilters[key]}),
          {}
        );
    }
    return filterParamData;
  }

  private openDetailsPage(routePath: string, customOutletName?: string, contextData?: DetailsCreationContext) {
    const outletName = customOutletName || this.configuration.ROUTER_OUTLETS.DETAILS;
    if (!routePath) {
      // No need of interception when closing details page
      return this.router.navigate(
        [{ outlets: { [outletName]: routePath } }],
        { state: { data: contextData }, queryParamsHandling: 'preserve' }
      );
    }

    this.actionInterceptorsService.callInterceptors(AppRoutingInterceptors.OnOpeningDetails)
      .subscribe(allowedToProceed => {
        if (!allowedToProceed) {
          return;
        }

        this.router.navigate(
          [{ outlets: {[outletName]: routePath } }],
          { state: { data: contextData}, queryParamsHandling: 'preserve' }
        );
      });
  }

  private openObjectCreationPage(routePath: string, inDrawer = false, contextData?: DetailsCreationContext) {
    if (inDrawer) {
      return this.openDetailsPage(`stack/${btoa(routePath[0])}`, this.configuration.ROUTER_OUTLETS.DRAWER_STACK, contextData);
    }
    return this.openDetailsPage(routePath, null, contextData);
  }

  isDetailsOpened(outletName?: string): boolean {
    const stateChildren = this.router.routerState.root.snapshot.children;
    const outlet = outletName || this.configuration.ROUTER_OUTLETS.DETAILS;
    return !!stateChildren.find(child => child.outlet === outlet);
  }

  public isDrawerOpen(): boolean {
    const outletName = this.isCEGEnabled ? this.configuration.ROUTER_OUTLETS.DRAWER_STACK : this.configuration.ROUTER_OUTLETS.DRAWER;
    return this.isDetailsOpened(outletName);
  }

  closeDetailsPage(errorMessage?: string, outletName?: string) {
    if (errorMessage) {
      this.utilityService.showToast({ Title: '', Message: errorMessage, Type: 'error' });
    }
    return this.openDetailsPage(null, outletName);
  }

  public closeActiveDrawer(isInvoiceDrawer?: boolean, isCurrentDrawerReplacing?: boolean, errorMessage?: string): Promise<boolean> {
    if (isInvoiceDrawer) {
      return this.closeDetailsPage(errorMessage, this.configuration.ROUTER_OUTLETS.DRAWER);
    } else {
      const configParam = this.stackManager.getCloseActiveDrawerParam();
      if (!configParam && isCurrentDrawerReplacing) {
        this.stackManager.setStackConfig({});
      }
      const routePath = configParam ? 'stack/' + configParam : null;
      return this.openDetailsPage(routePath, this.configuration.ROUTER_OUTLETS.DRAWER_STACK);
    }
  }

  public replaceActiveDrawer(drawerType: DrawerType, objectId?: number, context?: DetailsCreationContext): void {
    this.closeActiveDrawer(false, true);
    this.openChildDrawer(drawerType, objectId, context);
  }

  public closeStack(): Promise<boolean> {
    return this.closeDetailsPage('', this.configuration.ROUTER_OUTLETS.DRAWER_STACK);
  }

  closeAllDetailsWindows() {
    if (this.isDetailsOpened()) {
      return this.closeDetailsPage();
    }
    if (this.isDrawerOpen()) {
      return this.closeActiveDrawer();
    }
  }

  public openDetailsForObject(objectType: string, id: number, inDrawer = false): Promise<boolean> {
    if (inDrawer || this.isCEGEnabled) {
      const routeParam = btoa(objectType.toLowerCase()[0] + '-' + id);
      return this.openDetailsPage(`stack/${routeParam}`, this.configuration.ROUTER_OUTLETS.DRAWER_STACK);
    }
    const alternativeOutlet = inDrawer ? this.configuration.ROUTER_OUTLETS.DRAWER : null;
    return this.openDetailsPage(`${objectType}/${id}`, alternativeOutlet);
  }

  public goToParentDrawer(drawerType: DrawerType, objectId: number): Promise<boolean> {
    const routeParam = this.stackManager.getGoToParentDrawerParam(drawerType, objectId);
    if (!routeParam) {
      return this.closeStack();
    }
    return this.openDetailsPage(`stack/${routeParam}`, this.configuration.ROUTER_OUTLETS.DRAWER_STACK);
  }

  public openChildDrawer(drawerType: DrawerType, objectId?: number, context?: DetailsCreationContext): Promise<boolean> {
    const routeParam = this.stackManager.getAddActiveDrawerParam(drawerType, objectId);
    return this.openDetailsPage(`stack/${routeParam}`, this.configuration.ROUTER_OUTLETS.DRAWER_STACK, context);
  }

  public openChildDrawerCreation(drawerType: DrawerType, contextData?: BudgetObjectCreationContext): Promise<boolean> {
    const routeParam = this.stackManager.getAddActiveDrawerParam(drawerType);
    return this.openDetailsPage(`stack/${routeParam}`, this.configuration.ROUTER_OUTLETS.DRAWER_STACK, contextData);
  }

  private openDetailsForMetricMapping(mappingType: string, mappingId: number, backUrl?: string) {
    //md=1 => new metric drawer, md=0 => old metric ui page
    const searchParams = new URLSearchParams(window.location.search);

    let showMetricDrawer = true;

    if(searchParams.has('md')){
      showMetricDrawer = searchParams.get('md') === '1'
    }

    if(this.isCEGEnabled && showMetricDrawer) {
      if (!backUrl){
        return this.openDetailsForObject(
          this.configuration.OBJECT_TYPES.metric.toLowerCase(),
          mappingId,
          this.isCEGEnabled
        )
      } else {
        return this.openChildDrawer(
          DrawerType['Metric'],
          mappingId
        )
      }
    }
    return this.openDetailsPage(`${mappingType}_metric/${mappingId}`, null, { backUrl });
  }

  get isCEGEnabled(): boolean {
    return this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure;
  }

  openGoalDetails(goalId: number) {
    return this.openDetailsForObject(this.configuration.OBJECT_TYPES.goal.toLowerCase(), goalId, this.isCEGEnabled);
  }

  openCampaignDetails(campaignId: number) {
    return this.openDetailsForObject(this.configuration.OBJECT_TYPES.campaign.toLowerCase(), campaignId, this.isCEGEnabled);
  }

  openProgramDetails(programId: number) {
    return this.openDetailsForObject(this.configuration.OBJECT_TYPES.program.toLowerCase(), programId, this.isCEGEnabled);
  }

  openExpenseDetails(expenseId: number) {
    return this.openDetailsForObject(this.configuration.OBJECT_TYPES.expense.toLowerCase(), expenseId, true);
  }

  openInvoiceReview(expenseId: number): Promise<boolean> {
    return this.openDetailsPage(
      `${this.configuration.OBJECT_TYPES.invoiceReview.toLowerCase()}/${expenseId}`, this.configuration.ROUTER_OUTLETS.DRAWER
    );
  }

  openGoalCreation(contextData?: BudgetObjectCreationContext) {
    return this.openObjectCreationPage(this.configuration.ROUTING_CONSTANTS.CREATE_GOAL, this.isCEGEnabled, contextData);
  }

  openCampaignCreation(contextData?: BudgetObjectCreationContext) {
    return this.openObjectCreationPage(this.configuration.ROUTING_CONSTANTS.CREATE_CAMPAIGN, this.isCEGEnabled, contextData);
  }

  openProgramCreation(contextData?: BudgetObjectCreationContext) {
    return this.openObjectCreationPage(this.configuration.ROUTING_CONSTANTS.CREATE_PROGRAM, this.isCEGEnabled, contextData);
  }

  openExpenseCreation(contextData?: BudgetObjectCreationContext) {
    const { ROUTING_CONSTANTS} = this.configuration;
    return this.openObjectCreationPage(ROUTING_CONSTANTS.CREATE_EXPENSE, true, contextData);
  }

  openGoalMetricDetails(metricMappingId: number, backUrl?: string) {
    return this.openDetailsForMetricMapping(
      this.configuration.OBJECT_TYPES.goal.toLowerCase(),
      metricMappingId,
      backUrl
    );
  }

  openCampaignMetricDetails(metricMappingId: number, backUrl?: string) {
    return this.openDetailsForMetricMapping(
      this.configuration.OBJECT_TYPES.campaign.toLowerCase(),
      metricMappingId,
      backUrl
    );
  }

  openProgramMetricDetails(metricMappingId: number) {
    return this.openDetailsForMetricMapping(
      this.configuration.OBJECT_TYPES.program.toLowerCase(),
      metricMappingId
    );
  }

  openGoalMetricCreation(contextData?: DetailsCreationContext) {
    return this.openObjectCreationPage(this.configuration.ROUTING_CONSTANTS.CREATE_GOAL_METRIC.toLowerCase(), false, contextData);
  }

  openCampaignMetricCreation(contextData?: DetailsCreationContext) {
    return this.openObjectCreationPage(this.configuration.ROUTING_CONSTANTS.CREATE_CAMPAIGN_METRIC.toLowerCase(), false, contextData);
  }

  openPlanDetail(targetObjectTypes: string[], extraParams = {}, planPageViewMode: string = PlanPageViewMode.TABLE) {
    const openSections = targetObjectTypes.length ?
      targetObjectTypes
        .map(objectType => this.DASHBOARD_SECTION_BY_OBJECT_TYPE[objectType])
        .filter(section => !!section)
        .join(',') :
      null;

    const { ROUTING_CONSTANTS } = this.configuration;

    if (planPageViewMode === PlanPageViewMode.CARD && !this.isCEGEnabled) {
      return this.router.navigate([ROUTING_CONSTANTS.PLAN_DETAIL, openSections], extraParams);
    }

    // TODO: Retrieve 'last viewed' data mode from LS later
    const { routePath, dataMode } = this.getManagePageParams();
    const storageViewMode = LocalStorageService.getFromStorage(MANAGE_PAGE_VIEW_MODE);
    const mpViewMode = !!storageViewMode && openSections === ViewSectionName.campaigns
      ? storageViewMode
      : openSections;
    return this.router.navigate([routePath, dataMode, mpViewMode], extraParams);
  }

  getLocationState(pageContext: BackNavigationContext): NavigationToastrData {
    const filterParamData = this.parseFilterDataFromParams(this.router.routerState.root.snapshot.queryParams);
    return {
      pageName: pageContext.pageName,
      redirectUrl: pageContext.route,
      filterSet: filterParamData.filterSet,
    };
  }

  openManagePageForObjects(metricId: number, pageContext: BackNavigationContext) {
    LocalStorageService.addToStorage(LS_KEY_ACTIVE_METRIC_MASTER_ID, metricId);
    const locationState = this.getLocationState(pageContext);
    const { routePath, dataMode } = this.getManagePageParams();
    this.filterManagementService.updateCurrentFilterSet(
      {
        [FilterName.Metrics]: [metricId]
      }
    );
    return this.router.navigate(
      [routePath, dataMode, ViewSectionName.campaigns],
      { skipLocationChange: true, state: { backNavToastFor: locationState } }
    );
  }

  openManageCEGPageFromDrawer(filters: FilterSet, viewMode: ViewSectionName): Promise<boolean> {
    const routePath = this.configuration.ROUTING_CONSTANTS.MANAGE_CEG_PAGE;
    const dataMode = ManageCegDataMode.Budget;
    const queryParams  = this.getQueryParams();
    const filterParamData = this.parseFilterDataFromParams(queryParams);
    const { primaryPath, drawerStackPath } = this.getOutletPathsForBackNavigation();

    this.filterManagementService.updateCurrentFilterSet(filters);

    return this.router.navigate(
      [{
        outlets: {
          [this.configuration.ROUTER_OUTLETS.PRIMARY]: [routePath, dataMode, viewMode || ViewSectionName.campaigns],
          [this.configuration.ROUTER_OUTLETS.DRAWER_STACK]: null
        }
      }],
      {
        skipLocationChange: true,
        state: {
          backNavToastFor: { redirectUrl: primaryPath, filterSet: filterParamData.filterSet, drawerUrl: drawerStackPath },
          showByTimeframe: true
        }
      }
    ).then((data: boolean) => {
      this._triggerInitBackNavToast$.next();
      return Promise.resolve(data);
    });
  }

  openExpenseListFromManagePage(
    filterSet: FilterSet,
    togglingState: Record<string, boolean>,
    activeRowId: string,
    pageContext: BackNavigationContext,
    viewMode?: HierarchyViewMode
  ): void {
    LocalStorageService.addToStorage(LS_KEY_MANAGE_TABLE_STATE, { togglingState, activeRowId });

    const locationState = this.getLocationState(pageContext);
    this.router.navigate(
      [this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT],
      { skipLocationChange: true, state: { backNavToastFor: locationState, viewMode } }
    );
    this.filterManagementService.updateCurrentFilterSet(
      { ...this.filterManagementService.currentFilterSetValue, ...filterSet }
    );
  }

  openExpenseListFromManageCEGPage(filterSet: FilterSet, pageContext: BackNavigationContext, viewMode?: HierarchyViewMode): void {
    const locationState = this.getLocationState(pageContext);
    this.router.navigate(
      [this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT],
      { skipLocationChange: true, state: { backNavToastFor: locationState, viewMode } }
    );
    if(!filterSet.timeframes) {
      this.filterManagementService.setDisplayGoBack(true);
    }
    this.filterManagementService.updateCurrentFilterSet(
      { ...this.filterManagementService.currentFilterSetValue, ...filterSet }
    );
  }

  openExpenseListFromObjectDrawer(filterSet: FilterSet, viewMode: HierarchyViewMode): Promise<boolean> {
    const queryParams  = this.getQueryParams();
    const filterParamData = this.parseFilterDataFromParams(queryParams);
    const { primaryPath, drawerStackPath } = this.getOutletPathsForBackNavigation();

    // View on Expense page Go back Functionality with custom field filters applied
    let isExpenseCustomFieldFiltersSelected = Object.keys(this.filterManagementService.getCurrentSelectedCustomFieldFilterSet()).length > 0;

    if(isExpenseCustomFieldFiltersSelected) {
      this.customFieldFiltersManagementService.isCustomFieldFiltersSelected.next(false);
      this.customFieldFiltersManagementService.isCEGFiltersSelected.next(false)
    }


    this.filterManagementService.updateCurrentFilterSet(filterSet);

    return this.router.navigate(
      [{
        outlets: {
          [this.configuration.ROUTER_OUTLETS.PRIMARY]: [this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT],
          [this.configuration.ROUTER_OUTLETS.DRAWER_STACK]: null
        }
      }],
      {
        skipLocationChange: true,
        state: {
          backNavToastFor: { redirectUrl: primaryPath, filterSet: filterParamData.filterSet, drawerUrl: drawerStackPath },
          viewMode
        }
      }
    );
  }

  private getOutletPathsForBackNavigation(): { primaryPath: string; drawerStackPath: string; } {
    const routeStateChildren = this.router.routerState.root.snapshot.children;
    const primaryPath = this.getFullRoutePath(routeStateChildren, this.configuration.ROUTER_OUTLETS.PRIMARY);
    const drawerStackPath = this.getFullRoutePath(routeStateChildren, this.configuration.ROUTER_OUTLETS.DRAWER_STACK);
    return { primaryPath, drawerStackPath };
  }

  async openExpenseListInRelatedExpensesMode(relationGroupId: string) {
    this.filterManagementService.customFilterMode = {
      modeType: CustomFilterModeType.RelatedExpenses,
      context: { relationGroupId }
    };
    const activatedRoutePath = this.getCurrentActivatedPrimaryRoutePath();
    await this.closeAllDetailsWindows();
    if (activatedRoutePath !== this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT) {
      return this.router.navigate([this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT]);
    }
  }

  getCurrentActivatedPrimaryRoutePath(): string {
    return this.getRoutePath(this.activatedRoute.snapshot.firstChild);
  }

  getCurrentActivatedDetailsRouteData(): DetailsRouteData {
    const snapshotChildren = this.activatedRoute.snapshot.children;
    const detailsSnapshot = (snapshotChildren || []).find(child => child.outlet === this.configuration.ROUTER_OUTLETS.DETAILS);

    return {
      routePath: detailsSnapshot && this.getRoutePath(detailsSnapshot),
      idParam: detailsSnapshot?.params?.id
    };
  }

  getRoutePath(routeSnapshot: ActivatedRouteSnapshot): string {
    const { path: routeConfigPath } = routeSnapshot.routeConfig;
    return routeConfigPath.split('/')[0];
  }

  isCreateDetailsRoute(routeSnapshot: ActivatedRouteSnapshot, outletName?: string): boolean {
    if (!routeSnapshot) {
      return false;
    }

    const { routeConfig: { outlet, path }} = routeSnapshot;
    const { ROUTER_OUTLETS } = this.configuration;
    const targetOutlet = outletName || ROUTER_OUTLETS.DETAILS;

    // Check route path for matching <object_type>/create pattern
    return outlet === targetOutlet && /\/create$/.test(path);
  }

  private getManagePageParams(): Record<string, string> {
    const { ROUTING_CONSTANTS } = this.configuration;

    return this.isCEGEnabled
      ? { routePath: ROUTING_CONSTANTS.MANAGE_CEG_PAGE, dataMode: ManageCegDataMode.Budget }
      : { routePath: ROUTING_CONSTANTS.MANAGE_PAGE, dataMode: ManageTableDataMode.Allocation };
  }

  private getFullRoutePath(routeStateChildren: ActivatedRouteSnapshot[], outletName: string): string {
    const routeState = routeStateChildren.find(child => child.outlet === outletName);
    if (!routeState) {
      return null;
    }

    const getPath = (routeSnapshot: ActivatedRouteSnapshot): string => {
      if (!routeSnapshot) {
        return '';
      }

      const routeSnapshotUrlPart = routeSnapshot.url.map(urlSegment => urlSegment.path).join('/');
      const childSnapshotUrlParts = routeSnapshot.children.map(snapShot => getPath(snapShot)).join('/');
      return [routeSnapshotUrlPart, childSnapshotUrlParts].filter(part => part).join('/');
    };

    return getPath(routeState);
  }

  private getQueryParams(): Params {
    const url = window.location.href;
    return url.includes('?') ? { filter: new HttpParams({ fromString: url.split('?')[1] }).get('filter') } : {};
  }
}
