import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MenuPanelItem } from '../menu-panel/menu-panel.type';
import { CompanyDO } from 'app/shared/types/company.interface';
import {
  IntegrationSetupDialogComponent
} from 'app/metric-integrations/components/integration-setup-dialog/integration-setup-dialog.component';
import {
  AdsIntegrations,
  CampaignMappedIntegrations,
  MetricIntegrationDisplayName,
  MetricIntegrationName
} from 'app/metric-integrations/types/metric-integration';
import { DIALOG_CONFIG } from 'app/metric-integrations/components/integration-setup-dialog/integration-setup-dialog.config';
import { IntegrationSetupDialogData } from 'app/metric-integrations/types/integration-setup-dialog-data.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { Integration, IntegrationData } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { BudgetStatus } from 'app/shared/types/budget-status.type';
import { MetricIntegrations } from 'app/metric-integrations/types/metric-integrations-status.interface';
import { IntegrationSyncProgressService } from 'app/metric-integrations/services/integration-sync-process.service';
import { AuthExpiryModalComponent, AuthExpiryModalContext } from '../../../shared/modals/auth-expiry-modal/auth-expiry-modal.component';
import { AuthModalService } from '../../../shared/services/auth-modal.service';
import { CompanyUserDO } from '../../../shared/types/company-user-do.interface';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { CustomFieldStatusAndCountDO } from '../../../budget-object-details/components/custom-fields/custom-field.service';
import { CustomFieldsService } from '../../../budget-object-details/components/custom-fields/custom-field.service';

type CustomIcon = 'sso' | 'shared-cost' | 'integrations' | 'attributes' |
  MetricIntegrationName.Hubspot | MetricIntegrationName.Salesforce | MetricIntegrationName.GoogleAds;

interface SettingsMenuItem extends MenuPanelItem {
  customIcon?: CustomIcon;
  withDivider?: boolean;
  notifierMsg?: boolean;
  children?: SettingsMenuItem[];
}

interface IntegrationOptionsConfig {
  integrationName: MetricIntegrationName;
  configurationRoute: string;
  isDivider?: boolean;
  integrations: Integration[];
}

@Component({
  selector: 'settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled = false;
  @Input() showSettings = true;

  private readonly destroy$ = new Subject<void>();
  private integrationsAvailable = [
    MetricIntegrationName.Salesforce,
    MetricIntegrationName.Hubspot,
    MetricIntegrationName.GoogleAds,
    MetricIntegrationName.LinkedinAds,
    MetricIntegrationName.FacebookAds,
  ];
  private integrationsEntities: Record<string, Integration[]> = {};
  private company: CompanyDO;
  private routes = this.config.ROUTING_CONSTANTS;
  private defaultItemsConfig: { [key: string]: SettingsMenuItem } = {
    myTeam: {
      label: 'My Team',
      faIcon: ['fas', 'users'],
      action: () => this.navigateTo(this.routes.TEAM)
    },
    budget: {
      label: 'Budget',
      faIcon: ['fas', 'sack-dollar'],
      action: () => this.navigateTo(this.routes.BUDGET_SETTINGS)
    },
    currencies: {
      label: 'Currencies',
      faIcon: ['fas', 'euro-sign'],
      action: () => this.navigateTo(this.routes.CURRENCIES)
    },
    expenseTypes: {
      label: 'Expense Types',
      faIcon: ['fas', 'coins'],
      action: () => this.navigateTo(this.routes.ATTRIBUTES_TAGS, 'expenseTypes')
    },
    oldWorldExpenseTypes: {
      label: 'Expense Types',
      faIcon: ['fas', 'coins'],
      action: () => this.navigateTo(this.routes.EXPENSE_TYPES, 'expenseTypes')
    },
    vendors: {
      label: 'Vendors',
      faIcon: ['fas', 'user-helmet-safety'],
      action: () => this.navigateTo(this.routes.ATTRIBUTES_TAGS, 'vendors')
    },
    groupTypes: {
      label: 'Campaign / Expense Group Types',
      faIcon: ['far', 'rocket-launch'],
      action: () => this.navigateTo(this.routes.ATTRIBUTES_TAGS, 'groupTypes')
    },
    tags: {
      label: 'Tags',
      faIcon: ['fas', 'tags'],
      action: () => this.navigateTo(this.routes.ATTRIBUTES_TAGS, 'tags')
    },
    oldWorldTags: {
      label: 'Tags',
      faIcon: ['fas', 'tags'],
      action: () => this.navigateTo(this.routes.TAGS, 'tags')
    },
    glCodes: {
      label: 'GL Codes',
      faIcon: ['fas', 'rectangle-barcode'],
      action: () => this.navigateTo(this.routes.ATTRIBUTES_TAGS, 'glCodes')
    },
    oldWorldGlCodes: {
      label: 'GL Codes',
      faIcon: ['fas', 'rectangle-barcode'],
      action: () => this.navigateTo(this.routes.GL_CODES, 'glCodes')
    },
    metricsFunnel: {
      label: 'Metrics Funnel',
      faIcon: ['fas', 'chart-line-up'],
      action: () => this.navigateTo(this.routes.METRIC_FUNNELS)
    },
    sharedCostRules: {
      label: 'Shared Cost Rules',
      faIcon: null,
      customIcon: 'shared-cost',
      action: () => this.navigateTo(this.routes.SHARED_COST_RULES)
    },
    goalTypes: {
      label: this.config.attributesAndTagsLabel.GOAL_TYPES,
      faIcon: ['fas',  'bullseye-arrow'],
      action: () => this.navigateTo(this.routes.ATTRIBUTES_TAGS, 'goalTypes')
    },
    customFields: {
      label: 'Custom Fields',
      faIcon: ['fas', 'list-dropdown'],
      action: () => this.navigateTo(this.routes.CUSTOM_FIELDS)
    }
  };
  public sharedIconState = SharedIconState.Empty;
  public IntegrationSource = MetricIntegrationName;
  public items: SettingsMenuItem[] = [];
  public reauthRequiredForExternalIntegrations = false;
  public budget: Budget;
  private someIntegrationSyncIsActive = false;
  private activeIntegrationSyncs: Record<string, string> = {};
  currentUser : CompanyUserDO;
  private cegStatusEnabled: boolean;
  customFieldsStatus : CustomFieldStatusAndCountDO;

  constructor(
    private readonly router: Router,
    private config: Configuration,
    private companyDataService: CompanyDataService,
    private budgetDataService: BudgetDataService,
    private budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly matDialog: MatDialog,
    private readonly userManager: UserManager,
    private readonly integrationSyncProcessService: IntegrationSyncProgressService,
    private readonly authModalService: AuthModalService,
    private readonly CustomFieldsService : CustomFieldsService
  ) {
    this.budgetObjectDetailsManager.getCurrentBudget().subscribe(data => this.cegStatusEnabled = data.new_campaigns_programs_structure);
  }

  openIntegrationDialog(integrationSource: MetricIntegrationName, integration?: Integration): void {
    if (!integration && AdsIntegrations.includes(integrationSource)) {
      integration = {
        integrationId: null,
        data: this.defaultIntegrationData,
      };
    }

    const dialogData: IntegrationSetupDialogData = {
      integrationSource,
      integration,
      companyId: this.company.id,
    };

    this.matDialog.open(IntegrationSetupDialogComponent, {
      ...DIALOG_CONFIG,
      panelClass: 'reset-paddings',
      data: dialogData
    });
  }

  distinctChanged(prevIntegrations, currIntegrations) {
    return JSON.stringify(prevIntegrations) !== JSON.stringify(currIntegrations);
  }

  public currentIntegration : MetricIntegrations;

  ngOnInit(): void {

    this.userManager.currentCompanyUser$
    .pipe(
      filter(user => user != null),
      switchMap(user => {
        return combineLatest([
          this.companyDataService.selectedCompanyDO$.pipe(
            filter(company => company != null),
            tap(() => {
              this.clearSettingsMenu();
            }),
          ),
          this.companyDataService.metricIntegrations$.pipe(
            filter((integrationsObj: MetricIntegrations) => !Object.values(integrationsObj).some(val => val === null))
          )
        ]).pipe(
          tap(([company, integrations]) => {
            this.setUserData(user);
          })
        );
      }),
      takeUntil(this.destroy$)
    )
    .subscribe(([company, integrations]) => {
      this.company = company;
      this.budget = this.budgetDataService.selectedBudgetSnapshot;
      this.fillIntegrationsEntities(integrations);
      this.generateMenu();

      if(this.distinctChanged(this.currentIntegration, integrations)) {
        this.currentIntegration = integrations;
        const isAuthPopup = sessionStorage.getItem(`integration-authPopup`);
        const isAuthPopupBoolean = isAuthPopup === 'true';

        if (!isAuthPopupBoolean) {
          this.showAuthExpiryWarning(integrations);
        }
      }

    });

    this.integrationSyncProcessService.activeProcess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(activeIntegrations => {
        const someIntegrationSyncIsActive = !!Object.values(activeIntegrations).filter(status => !!status).length;
        const syncStatusChanged = someIntegrationSyncIsActive !== this.someIntegrationSyncIsActive;
        if (Object.keys(this.integrationsEntities).length && syncStatusChanged) {
          this.someIntegrationSyncIsActive = someIntegrationSyncIsActive;
          this.activeIntegrationSyncs = activeIntegrations;
          this.generateMenu();
        }
      });

      this.integrationSyncProcessService.reauthProcess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
            const companyDO = this.companyDataService.selectedCompanyDOSnapshot
            const budget = this.budgetDataService.selectedBudgetSnapshot
            this.companyDataService.getMetricIntegrations(budget, companyDO);
      });

      this.CustomFieldsService.getCFStatus().subscribe((data) => {
        this.customFieldsStatus = data;
        this.generateMenu();
      });

  }

  setUserData(user) {
    this.currentUser = user;
  }

  showAuthExpiryWarning(data_list: MetricIntegrations) {
    const filteredDataArray = Object.entries(data_list).map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => ({ [key]: [item] }));
      }
      return { [key]: value };
    }).flat().filter(obj => obj[Object.keys(obj)[0]][0].data?.reauthRequired);

    if (filteredDataArray.length) {
    sessionStorage.setItem(`integration-authPopup`, 'true');
      if(this.currentUser.is_admin) {
        this.handleAdminAuthExpiryWarning(filteredDataArray);
      }
      else {
        this.handleUserAuthExpiryWarning(filteredDataArray);
      }
    }
  }

  handleAdminAuthExpiryWarning(data_list, index = 0) {
    if (index >= data_list.length) {
      return; // Stop recursion if index is out of bounds
    }

    const platform = Object.keys(data_list[index])[0];
    const authExpiryModalContext: AuthExpiryModalContext = {
      title: 'Authentication has expired',
      content: 'Data from one or more of your ad networks may no longer be accurate.',
      iconType: platform,
      userType: 'admin'
    }

    const dialogRef = this.authModalService.openInitialUpdatePopup(authExpiryModalContext);


    dialogRef.subscribe((result) => {
      if (result) {
        this.authModalService.closePopup();
        const integration = data_list[index][platform][0];
        const subsequentDialogRef = this.authModalService.openSubsequentUpdatePopup({
          integrationSource: platform as MetricIntegrationName,
          companyId: this.company.id,
          integration: integration
        });

        subsequentDialogRef.subscribe((res) => {
          this.handleAdminAuthExpiryWarning(data_list, index + 1);
        });
      }
      else {
        this.handleAdminAuthExpiryWarning(data_list, index + 1);
      }
    });

  }

  handleUserAuthExpiryWarning(data_list, index = 0) {
    if (index >= data_list.length) {
      return; // Stop recursion if index is out of bounds
    }

    const platform = Object.keys(data_list[index])[0];
    const authExpiryModalContext: AuthExpiryModalContext = {
      title: 'Authentication has expired',
      content: 'Data from one or more of your ad networks may no longer be accurate. <br>Contact your administrator.',
      iconType: platform,
      userType: 'user'
    }

    const dialogRef = this.authModalService.openInitialUpdatePopup(authExpiryModalContext);


    dialogRef.subscribe((result) => {
      this.handleUserAuthExpiryWarning(data_list, index + 1);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.generateMenu();
    }
  }

  private get defaultIntegrationData(): IntegrationData {
    return {
      name: null,
      email: null,
      budgetId: null,
      importDataFrom: null,
    }
  }

  clearSettingsMenu() {
    this.items = [];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fillIntegrationsEntities(savedIntegrations): void {
    this.integrationsAvailable.forEach(integrationName => {
      this.integrationsEntities[integrationName] = savedIntegrations[integrationName] || [];
    })
  }

  private generateMenu() {
    this.items = this.cegStatusEnabled ? [
      this.defaultItemsConfig.myTeam,
      this.defaultItemsConfig.budget,
      this.defaultItemsConfig.currencies,
      this.defaultItemsConfig.metricsFunnel,
    ] : [
      this.defaultItemsConfig.myTeam,
      this.defaultItemsConfig.budget,
      this.defaultItemsConfig.currencies,
      this.defaultItemsConfig.oldWorldExpenseTypes,
      this.defaultItemsConfig.oldWorldGlCodes,
      this.defaultItemsConfig.metricsFunnel,
    ];

    const attributeItems = this.generateAttributesMenu();
    if (attributeItems && this.cegStatusEnabled) {
      this.items.push(attributeItems);
    }

    if (this.cegStatusEnabled && this.customFieldsStatus?.isCustomFieldEnabled) {
      this.items.push(this.defaultItemsConfig.customFields);
    }

    const integrationItems = this.generateIntegrationsMenu();
    if (integrationItems) {
      this.items.push(integrationItems);
    }else {
      this.reauthRequiredForExternalIntegrations = false;
    }

    this.items.push(
      {
        ...this.defaultItemsConfig.sharedCostRules,
        disabled: this.disabled
      }
    );

    if (!this.cegStatusEnabled) {
      this.items.push(this.defaultItemsConfig.oldWorldTags)
    }

  }

  private generateIntegrationOptions(config: IntegrationOptionsConfig): SettingsMenuItem[] {
    const { integrationName, configurationRoute, isDivider } = config;
    const integrationLabel = MetricIntegrationDisplayName[integrationName];
    const connectionStatus = !!config.integrations?.length;
    const integrationForEdit = AdsIntegrations.includes(integrationName) ?
      null :
      config.integrations[0];
    const connectedLabel = connectionStatus && AdsIntegrations.includes(integrationName) ?
      `Add ${integrationLabel}` :
      `${integrationLabel} Settings`
    const activationOption: SettingsMenuItem = {
      label: connectionStatus ? connectedLabel : `Enable ${integrationLabel}`,
      faIcon: null,
      customIcon: integrationName.toLowerCase() as CustomIcon,
      action: () => this.openIntegrationDialog(integrationName, integrationForEdit),
      isDivider,
      disabled: this.isReadOnlyBudget,
    };

    const generateConfigurationItem = (route: string, label: string): SettingsMenuItem => {
      return {
        label: integrationLabel + ' ' + label,
        faIcon: null,
        customIcon: integrationName.toLowerCase() as CustomIcon,
        action: () => configurationRoute && this.navigateTo(route),
        disabled: !connectionStatus || this.isReadOnlyBudget,
      }
    }
    const configurationOptions: SettingsMenuItem[] = [
      generateConfigurationItem(configurationRoute, 'Metric Mapping')
    ];
    const accountsItems: SettingsMenuItem[] = [];
    if (AdsIntegrations.includes(integrationName)) {

      const reauthRequired = config.integrations.some(integration => integration.data?.reauthRequired)

      if(reauthRequired) {
        const IntegrationDisplayName = MetricIntegrationDisplayName[integrationName];
        accountsItems.push({
          label: `${IntegrationDisplayName} connection has Expired`,
          faIcon: ["fas", "triangle-exclamation"],
          notifierMsg: true,
          action: () => {},
          disabled: true,
          isDivider: true,
          customCssClass: "auth-expiry-warn"
        })
      }
      config.integrations.forEach((integration, i) => {
        accountsItems.push({
          label: `${integration.data.name} Settings`,
          faIcon: null,
          customIcon: integrationName.toLowerCase() as CustomIcon,
          action: () => this.openIntegrationDialog(integrationName, integration),
          isDivider: !reauthRequired && i === 0,
          integrationId: integration.integrationId,
          disabled: this.isReadOnlyBudget,
          showLoading: this.someIntegrationSyncIsActive && integration.integrationId in this.activeIntegrationSyncs,
          customCssClass: integration.data.reauthRequired ? `auth-expiry-notifier ${integrationName.toLowerCase()}`: ''
        })
      })
    }

    if (CampaignMappedIntegrations.includes(integrationName)) {
      const route = `${this.config.ROUTING_CONSTANTS.CAMPAIGN_MAPPINGS}/${config.integrationName}`;
      configurationOptions.push(generateConfigurationItem(route, 'Campaign Mapping'));
    }

    return [activationOption, ...configurationOptions, ...accountsItems];
  }

  private setupFederatedLogin() {
    if (this.company) {
      this.userManager.setupFederatedLogin(this.company);
    }
  }

  private get isReadOnlyBudget(): boolean {
    return this.budget.status === BudgetStatus.Reference;
  }

  private disableSSO() {
    if (this.company) {
      this.userManager.disableSSO(this.company);
    }
  }

  private generateAttributesMenu(): SettingsMenuItem {
    const children: SettingsMenuItem[] = [];
    children.push(this.defaultItemsConfig.groupTypes);
    children.push(this.defaultItemsConfig.expenseTypes);
    children.push(this.defaultItemsConfig.goalTypes);
    children.push(this.defaultItemsConfig.vendors);
    children.push(this.defaultItemsConfig.glCodes);
    children.push(this.defaultItemsConfig.tags);
    return {
      label: 'Attributes & Tags',
      faIcon: null,
      customIcon: 'attributes',
      children
    };
  }

  private generateIntegrationsMenu(): SettingsMenuItem {
    if (!this.company || !this.budget) {
      return null;
    }
    const children: SettingsMenuItem[] = [];
    const { sso,
      sso_domain,
      sso_identity_provider,
      salesforce,
      hubspot,
      google_ads,
      linkedin_ads,
      facebook_ads
    } = this.company;

    const integrationStatuses = {
      [MetricIntegrationName.Salesforce]: salesforce,
      [MetricIntegrationName.Hubspot]: hubspot,
      [MetricIntegrationName.GoogleAds]: google_ads,
      [MetricIntegrationName.LinkedinAds]: linkedin_ads,
      [MetricIntegrationName.FacebookAds]: facebook_ads,
    }

    if (sso && sso_domain) {
      if (sso_identity_provider) {
        children.push({
          label: 'Disable SSO',
          customIcon: 'sso',
          faIcon: null,
          action: () => this.disableSSO(),
          disabled: !sso_identity_provider,
        });
      }

      children.push({
        label: 'SSO Settings',
        customIcon: 'sso',
        faIcon: null,
        action: () => this.setupFederatedLogin(),
        disabled: sso_identity_provider != null,
      });
    }

    let reauthRequired = false;
    this.integrationsAvailable.forEach(integrationSource => {
      if (integrationStatuses[integrationSource] === undefined) {
        console.error(`Missing status for "${integrationSource}" at integrationStatuses`)
        return;
      }
      if (integrationStatuses[integrationSource]) {

        let hasAuthExpired = this.integrationsEntities[integrationSource].some(integration => integration.data?.reauthRequired)
        if(hasAuthExpired) {
          reauthRequired = true;
        }

        const integrationOptions = this.generateIntegrationOptions({
          integrationName: integrationSource,
          configurationRoute: `${this.config.ROUTING_CONSTANTS.INTEGRATION_SETTINGS}/${integrationSource}`,
          integrations: this.integrationsEntities[integrationSource],
          isDivider: children.length > 0,
        });
        children.push(...integrationOptions);
        this.reauthRequiredForExternalIntegrations = reauthRequired;
      }
    })

    if (!children.length) {
      return null;
    }

    return {
      label: 'Integrations',
      faIcon: null,
      customIcon: 'integrations',
      children,
      customCssClass: this.reauthRequiredForExternalIntegrations ? 'auth-expiry-notifier': ''
    };
  }

  navigateTo(route: string, data: any = "") {
    data ? this.router.navigate([route], { queryParams: { mode: btoa(data) } }) : this.router.navigate([route])
  }
}
