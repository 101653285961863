import { Observable } from 'rxjs';
import { TableRowAmountsLoader } from '../../types/manage-ceg-table-row-data.types';
import { inject, Injectable } from '@angular/core';
import { BudgetSegmentService } from '@shared/services/backend/budget-segment.service';
import {
  BudgetTimeframeBrief,
  ManageCegTableCellAllocationAmount,
  ManageCegTableCellAllocations,
  ManageCegTableRow,
  ManageCegTableRowAllocations,
} from '@manage-ceg/types/manage-ceg-page.types';
import { ObjectAmountsByTimeframes } from '@shared/types/object-amounts.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { tap } from 'rxjs/operators';
import {
  addSegmentSpecificRowAllocationsData, calculatePresentationSegmentSum,
  getRowAllocationsFromAmountsData
} from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { createDeepCopy } from '@shared/utils/common.utils';
import { CachedSegmentAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/cached-segment-amounts-loader';
import { ComposedTableRowsDataLoader } from '@manage-ceg/services/manage-ceg-table-row-data/composed-table-rows-data-loader';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class SegmentGroupAmountsLoader implements TableRowAmountsLoader {
  private readonly segmentService = inject(BudgetSegmentService);

  public readonly rowType = ManageTableRowType.SegmentGroup;
  private segments: BudgetSegmentAccess[] = [];

  private static addSegmentAllocsToSegmentGroupAllocs(
    resultRowAllocations: ManageCegTableRowAllocations,
    segmentRowAllocations: ManageCegTableRowAllocations
  ): ManageCegTableRowAllocations {
    const resultTimeframeEntries =
      Object.entries(segmentRowAllocations).map(
        ([tfId, tableCellAllocations]) =>
          [tfId, SegmentGroupAmountsLoader.addTableCellAllocations(resultRowAllocations?.[tfId], tableCellAllocations)]
      );
    return Object.fromEntries(resultTimeframeEntries);
  }

  private static addTableCellAllocations(
    targetCellAllocations: ManageCegTableCellAllocations,
    incrementCellAllocations: ManageCegTableCellAllocations
  ): ManageCegTableCellAllocations  {
    if (!targetCellAllocations) {
      return createDeepCopy(incrementCellAllocations);
    }

    const resCellAllocationEntries = Object.entries(incrementCellAllocations).map(
      ([colName, allocAmounts]) =>
        [colName, SegmentGroupAmountsLoader.addCellColumnAmounts(targetCellAllocations[colName], allocAmounts)]
    );
    return Object.fromEntries(resCellAllocationEntries);
  }

  private static addCellColumnAmounts(
    targetCellColumnAmount: ManageCegTableCellAllocationAmount,
    incrementCellColumnAmount: ManageCegTableCellAllocationAmount
  ): ManageCegTableCellAllocationAmount {
    if (!targetCellColumnAmount) {
      return createDeepCopy(incrementCellColumnAmount);
    }

    const resAmount: ManageCegTableCellAllocationAmount = {
      ownAmount: targetCellColumnAmount.ownAmount + incrementCellColumnAmount.ownAmount
    };

    if (targetCellColumnAmount.campaignsAndPrograms != null || incrementCellColumnAmount.campaignsAndPrograms != null) {
      resAmount.campaignsAndPrograms =
        (targetCellColumnAmount.campaignsAndPrograms || 0) + (incrementCellColumnAmount.campaignsAndPrograms || 0);
    }

    if (targetCellColumnAmount.unallocated != null || incrementCellColumnAmount.unallocated) {
      resAmount.unallocated = (targetCellColumnAmount.unallocated || 0) + (incrementCellColumnAmount.unallocated || 0);
    }

    return resAmount;
  }

  public setSegments(segments: BudgetSegmentAccess[]): SegmentGroupAmountsLoader {
    this.segments = segments;
    return this;
  }

  public fillRowAmounts(
    budgetId: number,
    rows: ManageCegTableRow[],
    params: object,
    timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>
  ): Observable<any> {
    const groupedSegments = this.getGroupedSegments(rows.map(row => row.objectId));
    const segmentIds =
      Object.values(groupedSegments).reduce(
        (allSegmentIds, groupSegmentIds) => [...allSegmentIds, ...groupSegmentIds],
        []
      );

    return this.segmentService.getAmountsByTimeframes(budgetId, segmentIds, params).pipe(
      tap(segmentAmounts => {
        const { segments: segmentAmountsData, objects: objectsAmountData } = segmentAmounts;
        rows.forEach(row => this.fillRowAllocations(row, groupedSegments, segmentAmountsData, objectsAmountData, timeframesAll));
      })
    );
  }

  private getGroupedSegments(groupIds: number[]): Record<number, number[]> {
    return this.segments.reduce(
      (groups, segment) => {
        if (segment.segment_group && groupIds.includes(segment.segment_group)) {
          groups[segment.segment_group] = [...(groups[segment.segment_group] || []), segment.id];
        }
        return groups;
      },
      {}
    );
  }

  private fillRowAllocations(
    row: ManageCegTableRow,
    groupedSegments: Record<number, number[]>,
    segmentAmountsData: ObjectAmountsByTimeframes,
    objectsAmountData: ObjectAmountsByTimeframes,
    timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>
  ): void {
    const groupId = row.objectId;
    const segmentIds = groupedSegments[groupId];
    const groupSegmentRowAllocations: Record<any, ManageCegTableRowAllocations> = {};

    row.allocations = segmentIds.reduce(
      (resultRowAllocations: ManageCegTableRowAllocations, segmentId: number): ManageCegTableRowAllocations => {
        const segmentRowAllocations = getRowAllocationsFromAmountsData(segmentAmountsData[segmentId]);
        addSegmentSpecificRowAllocationsData(segmentRowAllocations, objectsAmountData[segmentId]);
        groupSegmentRowAllocations[segmentId] = segmentRowAllocations;
        return SegmentGroupAmountsLoader.addSegmentAllocsToSegmentGroupAllocs(resultRowAllocations, segmentRowAllocations);
      },
      null
    );
    row.presentationAllocations = calculatePresentationSegmentSum<ManageCegTableCellAllocations>(row.allocations, timeframesAll);

    row.childRowsDataLoader =
      new ComposedTableRowsDataLoader().setRowDataLoader(
        ManageTableRowType.Segment,
        new CachedSegmentAmountsLoader(groupSegmentRowAllocations)
      );
  }
}
