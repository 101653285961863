import { Injectable } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faUserCrown, IconDefinition, faBullseyeArrow, faPiggyBank, faTags, faClipboard, faBarcodeAlt,
  faFileAlt, faUserHardHat, faCalendarAlt, faSitemap, faBriefcase, faInfoCircle, faCalendarDay,
  faRocketLaunch, faDownload, faTrashAlt, faGraduationCap, faHandPointDown, faPennant, faAnalytics, faMoneyBillWave,
  faAlicorn, faChartLine, faWindowClose, faCaretCircleDown, faCaretCircleUp, faClock, faEdit,
  faTasks, faTruck, faGlassCheers, faSackDollar, faHeadSideMask, faQuestionCircle,
  faSlidersH, faTimesCircle, faCog, faSearch, faUserPlus as faUserPlusDuotone, faSocks, faExclamationTriangle, faChess, faTachometerAlt, faFileImport,
  faHandPointRight, faStickyNote, faPencil, faPlusCircle, faSatelliteDish, faTable, faPlug, faMinusCircle, faCopy,
  faSortDown, faSort, faDraftingCompass, faSignalStream, faVial, faEye, faMinusSquare, faPlusSquare,
  faPen, faEnvelope, faEyeSlash, faSave, faRoute, faVirus, faCameraAlt, faSkull, faChartPieAlt, faFolderOpen,
  faCloudDownload, faHourglassStart, faRaygun, faLock, faLockAlt, faHourglassHalf, faHourglassEnd, faRocket, faPencilAlt,
  faFingerprint, faTools, faLink, faFileDownload, faCheckSquare as faCheckSquareDuotone, faBadgeCheck
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faOutlet,
  faExclamationTriangle as faExclamationTriangleSolid,
  faMask,
  faChevronUp,
  faChevronDown as faSolidChevronDown,
  faCaretDown,
  faInfoCircle as faInfoCircleSolid,
  faKey,
  faBan,
  faGripVertical as faGripVerticalSolid,
  faThLarge,
  faUndo,
  faCaretUp,
  faHeartRate,
  faHeart as faHeartSolid,
  faCheckSquare,
  faGripVertical,
  faCalendarDays,
  faClockRotateLeft,
  faMagnifyingGlass,
  faGear,
  faSliders,
  faCircleXmark,
  faStar,
  faBriefcase as faBriefcaseSolid,
  faBullseyeArrow as faBullseyeArrowSolid,
  faFileImport as faFileImportSolid,
  faFileChartColumn,
  faCoins,
  faAlarmExclamation,
  faHeadSideMask as faHeadSideMaskSolid,
  faCalendarWeek,
  faCircleQuestion,
  faComments,
  faPersonFromPortal,
  faUsers,
  faUser,
  faSackDollar as faSackDollarSolid,
  faEuroSign,
  faRectangleBarcode,
  faChartLineUp,
  faTags as faTagsSolid,
  faPlug as faPlugSolid,
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
  faTachometerAlt as faTachometerAltSolid,
  faChartLine as faChartLineSolid,
  faExclamationCircle,
  faInbox,
  faSealExclamation,
  faFileInvoiceDollar,
  faCloudArrowUp,
  faScrewdriverWrench,
  faPencilRuler,
  faSort as faSortSolid,
  faLineColumns,
  faCloudDownload as faCloudDownloadSolid,
  faHistory,
  faLayerPlus,
  faPlus,
  faCircleCheck,
  faCircleExclamation,
  faGraduationCap as faGraduationCapSolid,
  faFileInvoice as faFileInvoiceSolid,
  faBuildings,
  faTable as faTableSolid,
  faChartColumn, faLockKeyhole, faArrowLeft, faArrowRight,
  faDisplayCode,
  faWavePulse,
  faCopy as faCopySolid,
  faFolderOpen as faFolderOpenSolid,
  faHourglassEnd as faHourglassEndSolid,
  faHourglassStart as faHourglassStartSolid,
  faTrashAlt as faTrashAltSolid,
  faCloudArrowDown,
  faCalendarRange,
  faListTree,
  faPiggyBank as faPiggyBankSolid,
  faTrash,
  faChevronRight as faChevronRightSolid,
  faRocketLaunch as faRocketLaunchSolid,
  faFileAlt as faFileAltSolid,
  faUserHardHat as faUserHardHatSolid,
  faFingerprint as faFingerprintSolid,
  faUserCrown as faUserCrownSolid,
  faMartiniGlassCitrus,
  faTruck as faTruckSolid,
  faChartPieAlt as faChartPieAltSolid,
  faSitemap as faSitemapSolid,
  faLink as faLinkSolid,
  faClipboard as faClipboardSolid,
  faXmark,
  faUserAstronaut,
  faPen as faPenSolid,
  faListDropdown,
  faPenToSquare,
  faFloppyDisk,
  faCircleArrowLeft,
  faFilter,
  faFilterCircleXmark
} from '@fortawesome/pro-solid-svg-icons';
import {
  faPaperclip,
  faTimes,
  faUserPlus,
  faChevronRight,
  faChevronDown,
  faLockOpen as faLockOpenRegular,
  faEllipsisV,
  faHeart,
  faCheck,
  faRocketLaunch as faRocketLaunchRegular,
  faRocket as faRocketRegular, faChartPie, faChartPieSimple,
  faPen as faPenRegular
} from '@fortawesome/pro-regular-svg-icons';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { faHeart as faHeartD } from '@fortawesome/pro-duotone-svg-icons/faHeart';
import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons/faFileChartLine';
import { faArrowAltFromLeft } from '@fortawesome/pro-duotone-svg-icons/faArrowAltFromLeft';

@Injectable({
  providedIn: 'root'
})
export class FontAwesomeService {

  private icons: IconDefinition[] = [
    faUserCrown, faBullseyeArrow, faPiggyBank, faTags, faClipboard, faBarcodeAlt, faFileAlt,
    faUserHardHat, faCalendarAlt, faCalendarDays, faSitemap, faBriefcase, faInfoCircle, faCalendarDay,
    faRocketLaunch, faPaperclip, faDownload, faTrashAlt, faGraduationCap, faHandPointDown, faPennant, faAnalytics,
    faMoneyBillWave, faAlicorn, faChartLine, faWindowClose, faCaretCircleDown, faCaretCircleUp,
    faClock, faEdit, faTasks, faUserPlus, faTruck, faTimes, faFileInvoiceDollar, faChevronRight, faChevronUp, faChevronDown, faGlassCheers,
    faSackDollar, faHeadSideMask, faQuestionCircle, faSlidersH, faTimesCircle, faHistory, faCog, faAlarmExclamation,
    faSearch, faUsers, faUser, faUserPlusDuotone, faEuroSign, faSocks, faExclamationTriangle, faChess, faTachometerAlt, faLayerPlus, faPlus, faCalendarWeek,
    faFileImport, faHandPointRight, faStickyNote, faPencil, faPlusCircle, faSatelliteDish, faOutlet, faExclamationTriangleSolid, faTable,
    faMask, faPlug, faHubspot, faSolidChevronDown, faCaretDown, faMinusCircle, faCopy, faSortDown, faSort, faInfoCircleSolid, faSkull,
    faLock, faLockAlt, faDraftingCompass, faSignalStream, faVial, faEye, faLockKeyhole, faLockOpenRegular, faMinusSquare, faPlusSquare, faHourglassHalf,
    faHourglassEnd, faKey, faPen, faEnvelope, faEllipsisV, faEyeSlash, faHeart, faHeartSolid, faHeartD, faSave, faCheck, faRoute, faVirus, faCameraAlt,
    faBan, faGripVerticalSolid, faSkull, faChartPieAlt, faChartPieSimple, faFolderOpen, faCloudDownload, faChartPie, faThLarge, faHourglassStart, faRaygun,
    faRocket, faUndo, faCaretUp, faPencilAlt, faComments, faFingerprint, faTools, faLink, faFileChartLine, faArrowAltFromLeft,
    faPencilRuler, faSort, faLineColumns, faHeartRate, faCheckSquare, faFileDownload, faGripVertical, faCheckSquareDuotone,
    faClockRotateLeft, faMagnifyingGlass, faGear, faSliders, faCircleXmark, faStar, faRocketLaunchRegular, faRocketRegular,
    faBriefcaseSolid, faBullseyeArrow, faBullseyeArrowSolid, faFileImportSolid, faFileChartColumn, faCoins, faHeadSideMaskSolid, faCircleQuestion,
    faPersonFromPortal, faSackDollarSolid, faRectangleBarcode, faChartLineUp, faTagsSolid, faPlugSolid, faEyeSolid, faEyeSlashSolid,
    faTachometerAltSolid, faChartLineSolid, faExclamationCircle, faInbox, faSealExclamation, faBadgeCheck, faCloudArrowUp, faScrewdriverWrench,
    faCloudDownloadSolid, faSortSolid, faCircleCheck, faCircleExclamation, faGraduationCapSolid, faFileInvoiceSolid, faBuildings, faPenRegular,
    faTableSolid, faChartColumn, faArrowLeft, faArrowRight, faDisplayCode, faWavePulse, faCopySolid, faFolderOpenSolid, faHourglassEndSolid, faHourglassStartSolid,
    faTrashAltSolid, faCloudArrowDown, faCalendarRange, faListTree, faPiggyBankSolid, faTrash, faChevronRightSolid, faRocketLaunchSolid,
    faFileAltSolid, faUserHardHatSolid, faFingerprintSolid, faUserCrownSolid, faTruckSolid, faChartPieAltSolid, faSitemapSolid, faLinkSolid,
    faClipboardSolid, faMartiniGlassCitrus, faXmark, faUserAstronaut, faPenSolid, faListDropdown, faFloppyDisk, faPenToSquare, faCircleArrowLeft, faFilter, faFilterCircleXmark
  ];

  initIcons(library: FaIconLibrary) {
    library.addIcons(...this.icons);
  }
}
