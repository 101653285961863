import { inject, Injectable } from '@angular/core';
import { BudgetSegmentService } from '@shared/services/backend/budget-segment.service';
import {
  BudgetTimeframeBrief,
  ManageCegTableCellAllocations,
  ManageCegTableRow,
} from '@manage-ceg/types/manage-ceg-page.types';
import { Observable } from 'rxjs';
import { TableRowAmountsLoader } from '@manage-ceg/types/manage-ceg-table-row-data.types';
import { tap } from 'rxjs/operators';
import {
  addSegmentSpecificRowAllocationsData, calculatePresentationSegmentSum,
  getRowAllocationsFromAmountsData,
} from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { ObjectAmountsByTimeframes } from '@shared/types/object-amounts.interface';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class SegmentAmountsLoader implements TableRowAmountsLoader {
  private readonly segmentService = inject(BudgetSegmentService);
  public readonly rowType = ManageTableRowType.Segment;

  private static fillRowAllocations(
    row: ManageCegTableRow,
    segmentAmountsData: ObjectAmountsByTimeframes,
    objectsAmountData: ObjectAmountsByTimeframes,
    timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>
  ): void {
    const rowAllocations = getRowAllocationsFromAmountsData(segmentAmountsData[row.objectId]);
    addSegmentSpecificRowAllocationsData(rowAllocations, objectsAmountData[row.objectId]);
    row.allocations = rowAllocations;
    row.presentationAllocations = calculatePresentationSegmentSum<ManageCegTableCellAllocations>(row.allocations, timeframesAll);
  }

  public fillRowAmounts(
    budgetId: number,
    rows: ManageCegTableRow[],
    params: object,
    timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>
  ): Observable<any> {
    return this.segmentService.getAmountsByTimeframes(budgetId, rows.map(row => row.objectId), params).pipe(
      tap(amountsData => {
        const { segments: segmentAmountsData, objects: objectsAmountData } = amountsData;
        rows.forEach(row => SegmentAmountsLoader.fillRowAllocations(row, segmentAmountsData, objectsAmountData, timeframesAll));
      })
    );
  }
}
