<grid-with-search
  [gridData]="groupTypeList"
  [gridColumns]="gridColumns"
  (deleteGridItem)="deleteGroupType($event)"
  (addNewGridItem)="addGroupType($event)"
  (editGridItem)="updateGroupType($event)"
  (updateStatusChange)="updateStatusChange($event)"
  [enableSwitchTooltip]="enableSwitchTooltip"
  [SearchPlaceHolder]="SearchPlaceHolder"
  [editableMaxLength]="128"
  [customActions]="true"
  [customActionLabel]="'is_custom'"
  [NoDataText]="NoDataText"
></grid-with-search>